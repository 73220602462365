import React, { ChangeEvent, SyntheticEvent } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteButton, { IDeleteButtonProps } from '../DeleteButton';
import ConfirmDialog, { IConfirmDialogProps } from '../ConfirmDialogComponent';
import AddNewElement, { IAddNewElementProps } from '../AddNewElement';
import Radio from '@material-ui/core/Radio';
import { API_BASE_URL } from '../../configuration/config';
import * as frontendHelpers from '../../helpers/helpers';
import NotificationsComponent, { variantIcon } from '../NotificationsComponent';
import { Cookies, withCookies } from 'react-cookie';
import LogoutAlert, { ILogoutProps } from '../LogoutAlert';
import Loading from '../Loading';
import fetch from "node-fetch"
import { WithTranslation, withTranslation } from 'react-i18next';


const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        display: 'flex'
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },

});


interface IProps extends WithStyles<typeof styles>, WithTranslation {
    cookies: Cookies;
}

interface IPlaylists {
    id: number,
    spotify_id: string,
    playlist_name: string,
    is_default: boolean
}

interface IState {
    playlists: Array<IPlaylists>
    openConfirmDialog: boolean,
    playlistIndex: number,
    playlistsToAdd: string,
    indexOfDefaultPlaylist: string,
    openSnackbar: boolean,
    snackbarVariant: keyof typeof variantIcon,
    snackbarMessage: string | React.ReactNode,
    jwtToken: string,
    logout: boolean,
    redirectToLogin: boolean,
}


class CustomizedTable extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        const { cookies } = this.props
        this.state = { playlists: [], openConfirmDialog: false, playlistIndex: -1, playlistsToAdd: '', indexOfDefaultPlaylist: '', openSnackbar: false, snackbarVariant: 'error', snackbarMessage: '', jwtToken: cookies.get('jwtToken'), logout: false, redirectToLogin: false }
    }

    public componentWillMount = async () => {
        const { jwtToken } = this.state
        const { t } = this.props

        try {
            const fetchPlaylistsResult = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}getPlaylists`, {
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            }, 14000)

            if (fetchPlaylistsResult.status === 401 || fetchPlaylistsResult.status === 403) {
                this.setState({ logout: true })
            } else {
                const fetchPlaylistsResultParsed = await fetchPlaylistsResult.json()

                let playlistsToDisplay = []
                let indexOfDefaultPlaylist: string = ''

                if (fetchPlaylistsResultParsed.code !== 200) {
                    this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("SERVER_NOT_AVAILABLE") })
                } else {

                    for (const playlist of fetchPlaylistsResultParsed.playlistsInfo) {

                        playlistsToDisplay.push(playlist)
                        if (playlist.is_default) {
                            indexOfDefaultPlaylist = String(playlistsToDisplay.indexOf(playlist))
                        }
                    }
                }

                this.setState({ playlists: playlistsToDisplay, indexOfDefaultPlaylist: indexOfDefaultPlaylist })
            }
        } catch (error) {
            const state = frontendHelpers.fetchWithTimeoutErrorHandler(error)
            this.setState({ redirectToLogin: true, openSnackbar: true, snackbarVariant: state.snackbarVariant, snackbarMessage: state.snackbarMessage })
        }
    }

    public render() {
        const { classes, t } = this.props;
        const { playlists } = this.state

        return (
            <div>
                {this.renderAddNewElementComponent()}
                <Paper className={classes.root}>
                    {playlists.length === 0 ? (<Loading></Loading>) :
                        (<Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell align="center">{t("PLAYLISTS.TABLE_HEAD.PLAYLIST_ID")}</CustomTableCell>
                                    <CustomTableCell align="center">{t("PLAYLISTS.TABLE_HEAD.PLAYLIST_NAME")}</CustomTableCell>
                                    <CustomTableCell align="center">{t("PLAYLISTS.TABLE_HEAD.DEFAULT")}</CustomTableCell>
                                    <CustomTableCell align="center">{t("PLAYLISTS.TABLE_HEAD.DELETE")}</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {playlists.map((playlist, index) => (
                                    <TableRow className={classes.row} key={playlist.id}>
                                        <CustomTableCell align="center">{playlist.spotify_id}</CustomTableCell>
                                        <CustomTableCell align="center">{playlist.playlist_name}</CustomTableCell>
                                        <CustomTableCell align="center">{this.renderRadioButton(index)}</CustomTableCell>
                                        <CustomTableCell align="center">{this.renderDeleteButton(index)}</CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>)}
                    {this.state.openConfirmDialog && this.renderConfirmDialog()}
                    {this.state.logout && this.renderLogoutAlert()}
                    {frontendHelpers.redirectToLogin(this.state.redirectToLogin)}
                </Paper>
                {this.state.openSnackbar && this.renderSnackbar()}
            </div>
        );
    }

    /* render logout alert to inform the user that his session is expired */
    private renderLogoutAlert = () => {
        const { logout } = this.state
        const logoutAlertProps: ILogoutProps = {
            openLogoutAlert: logout,
            handleCloseLogoutAlert: this.handleCloseLogoutAlert
        }
        return <LogoutAlert {...logoutAlertProps}></LogoutAlert>
    }

    private handleCloseLogoutAlert = () => {
        const { cookies } = this.props
        /* clear cookies and redirect to login */
        frontendHelpers.clearCookiesAndRedirect(cookies)
    };

    /* render snackbar */
    private renderSnackbar = () => {
        const { openSnackbar, snackbarVariant, snackbarMessage } = this.state
        return (
            <NotificationsComponent
                variant={snackbarVariant}
                message={snackbarMessage}
                open={openSnackbar}
                handleClose={this.closeSnackbar}></NotificationsComponent>
        )
    }

    /* handle closing the snackbar */
    private closeSnackbar = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false })
    }

    /* render radio button to manage default playlist */
    private renderRadioButton = (index: number) => {
        const { indexOfDefaultPlaylist } = this.state

        return (
            <Radio
                checked={String(index) === indexOfDefaultPlaylist}
                onChange={this.handleChangeRadioButton(index)}
                color="primary"
            />
        )
    }

    private handleChangeRadioButton = (index: number) => async (event: ChangeEvent<HTMLElement>) => {

        const newPlaylists = [...this.state.playlists]
        const oldIndexOfDefaultPlaylist = parseInt(this.state.indexOfDefaultPlaylist)
        const { jwtToken } = this.state
        const { t } = this.props

        /* update the default playlist */
        const updateDefaultPlaylistResult = await fetch(`${API_BASE_URL}updateDefaultPlaylist/${newPlaylists[index].id}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })
        if (updateDefaultPlaylistResult.status === 401 || updateDefaultPlaylistResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const updateDefaultPlaylistResultParsed = await updateDefaultPlaylistResult.json()

            if (updateDefaultPlaylistResultParsed.error) {
                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("PLAYLISTS.FAILED_DEFAULT_UPDATE") })
            } else {
                /* set the old default playlist to false */
                newPlaylists[oldIndexOfDefaultPlaylist] = {
                    ...newPlaylists[oldIndexOfDefaultPlaylist],
                    is_default: false
                }

                /* set the new default playlist to true */
                newPlaylists[index] = {
                    ...newPlaylists[index],
                    is_default: true
                }
                this.setState({ playlists: newPlaylists, indexOfDefaultPlaylist: String(index), openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("PLAYLISTS.DEFAULT_UPDATED") })
            }
        }
    }

    /* render textfield and confirm button to add new backup songs */
    private renderAddNewElementComponent = () => {
        const { t } = this.props
        const componentProps: IAddNewElementProps = {
            textFieldId: "spotifyPlaylistId",
            textFieldLabel: t("PLAYLISTS.TEXTFIELD_LABEL"),
            textFieldPlaceholder: t("PLAYLISTS.TEXTFIELD_PLACEHOLDER"),
            textFieldRowNumber: "2",
            onTextChange: this.onTextChange,
            onAddNewButtonClick: this.onAddNewButtonClick,
            textFieldContent: this.state.playlistsToAdd
        }

        return <AddNewElement {...componentProps}></AddNewElement>
    }

    private onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ playlistsToAdd: event.target.value })
    }

    private onAddNewButtonClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const { playlistsToAdd, jwtToken } = this.state
        const { t } = this.props

        const addNewPlaylistResult = await fetch(`${API_BASE_URL}addPlaylist/${playlistsToAdd}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })
        if (addNewPlaylistResult.status === 401 || addNewPlaylistResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const addNewPlaylistResultParsed = await addNewPlaylistResult.json()

            if (addNewPlaylistResultParsed.error) {
                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("PLAYLISTS.FAILED_ADD_PLAYLIST") })
            } else {
                const newPlaylists = [...this.state.playlists]
                for (const playlist of addNewPlaylistResultParsed.succeeded) {
                    newPlaylists.push(playlist)
                }
                if (addNewPlaylistResultParsed.code === 207) {
                    let failedPlaylistsList = []
                    for (const failedPlaylist of addNewPlaylistResultParsed.failed) {
                        let reason
                        if (failedPlaylist.code === 409) {
                            reason = t("PLAYLISTS.PLAYLIST_EXISTS")
                        } else if (failedPlaylist.code === 400){
                            reason = t("PLAYLISTS.SPOTIFY_ACCOUNT_MISSMATCH")
                        } else {
                            reason = t("PLAYLISTS.ID_INVALID")
                        }
                        failedPlaylistsList.push({ id: failedPlaylist.spotify_playlist_id, reason: reason })
                    }

                    /* - format the notification as follows --- failed id : reason why ---
                       - rerender the textfield component with only the failed ids
                    */
                    let failedIDs = ""
                    const failedPlaylistsListFormatted = failedPlaylistsList.map(({ id, reason }) => {
                        failedIDs = failedIDs + " " + id
                        return <div key={id}><b>{id}: </b>{reason}</div>
                    })

                    const warningMessage = <div>{t("PLAYLISTS.WARNING_HEADER")}<br />{failedPlaylistsListFormatted}</div>
                    this.setState({ playlistsToAdd: failedIDs.trim(), playlists: newPlaylists, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: warningMessage })

                } else {
                    let snackbarMessage
                    if (addNewPlaylistResultParsed.succeeded.length > 1) {
                        snackbarMessage = t("PLAYLISTS.ADDED_MULTIPLE")
                    } else {
                        snackbarMessage = t("PLAYLISTS.ADDED_ONE")
                    }

                    this.setState({ playlistsToAdd: "", playlists: newPlaylists, openSnackbar: true, snackbarVariant: "success", snackbarMessage: snackbarMessage })
                }
            }
        }

    }

    /* render user delete button */
    private renderDeleteButton = (index: number) => {

        const deleteButtonComponentProps: IDeleteButtonProps = {
            index: index,
            onDeleteButtonClick: this.onDeleteButtonClick
        }

        return <DeleteButton {...deleteButtonComponentProps}></DeleteButton>
    }

    /* open confirm dialog when deleting a user */
    private onDeleteButtonClick = (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        this.setState({ openConfirmDialog: true, playlistIndex: index })
    }

    /* delete backup song */
    private deletePlaylistSong = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const newPlaylists = [...this.state.playlists]
        const { jwtToken } = this.state
        const { t } = this.props

        /* user must select a new default playlist before deleting the current one to avoid unexpected behavior */
        if (newPlaylists[index].is_default) {
            this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("PLAYLISTS.DELETE.WARNING_DEFAULT") })
        } else {
            const deletePlaylistResult = await fetch(`${API_BASE_URL}deletePlaylist/${newPlaylists[index].spotify_id}`, {
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            })
            if (deletePlaylistResult.status === 401 || deletePlaylistResult.status === 403) {
                this.setState({ logout: true })
            } else {
                const deletePlaylistResultParsed = await deletePlaylistResult.json()

                if (deletePlaylistResultParsed.error) {
                    if (deletePlaylistResultParsed.error.code === 404) {
                        this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("PLAYLISTS.DELETE.NOT_EXIST") })
                    } else if (deletePlaylistResultParsed.error.code === 207) {
                        this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("PLAYLISTS.DELETE.SONGS_NOT_UPDATED") })
                    } else {
                        this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("PLAYLISTS.DELETE.ERROR") })
                    }
                } else {
                    newPlaylists.splice(index, 1)

                    /* update index of default playlist to avoid unwanted behavior */
                    let newIndexOfDefaultPlaylist: string = ''
                    for (const playlist of newPlaylists) {
                        if (playlist.is_default) {
                            newIndexOfDefaultPlaylist = String(newPlaylists.indexOf(playlist))
                        }
                    }
                    this.setState({ playlists: newPlaylists, indexOfDefaultPlaylist: newIndexOfDefaultPlaylist, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("PLAYLISTS.DELETE.SUCCESS") })
                }
            }
        }
    }

    /* render confirm dialog on the main component */
    private renderConfirmDialog = () => {
        const { openConfirmDialog, playlistIndex } = this.state

        const confirmDialogComponentProps: IConfirmDialogProps = {
            openConfirmDialog,
            index: playlistIndex,
            withDescription: false,
            handleCloseConfirmDialog: this.handleCloseConfirmDialog,
            action: this.deletePlaylistSong,

        }

        return <ConfirmDialog {...confirmDialogComponentProps}></ConfirmDialog>

    }

    /* dismiss confirm dialog either when delete is done or canceled */
    private handleCloseConfirmDialog = () => {
        this.setState({ openConfirmDialog: false });
    };

}

export default withTranslation()(withCookies(withStyles(styles)(CustomizedTable)));