import React, { SyntheticEvent } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Fab, Switch } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import fetch from 'node-fetch'
import UserDialogComponent, { IUserDialogProps } from './UserDialogComponent'
import ConfirmDialog, { IConfirmDialogProps } from '../ConfirmDialogComponent';
import DeleteButton, { IDeleteButtonProps } from '../DeleteButton';
import { API_BASE_URL } from '../../configuration/config';
import * as frontendHelpers from '../../helpers/helpers';
import NotificationsComponent, { variantIcon } from '../NotificationsComponent';
import { Cookies, withCookies } from 'react-cookie';
import LogoutAlert, { ILogoutProps } from '../LogoutAlert';
import Loading from '../Loading';
import { WithTranslation, withTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
    root: {
        width: '98%',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    fab: {
        margin: theme.spacing(1),
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
    }
});


interface IProps extends WithStyles<typeof styles>, WithTranslation {
    cookies: Cookies;
}

export interface IUser {
    id?: number,
    firstName: string,
    lastName: string,
    emailAddress: string,
    password?: string
    isAdmin?: boolean
}

interface IState {
    users: Array<IUser>,
    openUserDialog: boolean,
    openConfirmDialog: boolean,
    userIndex: number,
    isNewUser: boolean,
    openSnackbar: boolean,
    snackbarVariant: keyof typeof variantIcon,
    snackbarMessage: string,
    jwtToken: string,
    logout: boolean,
    redirectTologin: boolean,
}


class userManagement extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        const { cookies } = this.props

        this.state = { users: [], openUserDialog: false, openConfirmDialog: false, userIndex: -1, isNewUser: false, openSnackbar: false, snackbarVariant: 'error', snackbarMessage: '', jwtToken: cookies.get('jwtToken'), logout: false, redirectTologin: false }
    }

    public componentWillMount = async () => {
        const { jwtToken } = this.state
        const { t } = this.props

        try {
            const fetchUsersResponse = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}getAllUsers`, {
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            }, 14000)
            if (fetchUsersResponse.status === 401 || fetchUsersResponse.status === 403) {
                this.setState({ logout: true })
            } else {
                const fetchUsersResponseParsed = await fetchUsersResponse.json()

                if (fetchUsersResponseParsed.code !== 200) {
                    this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("SERVER_NOT_AVAILABLE") })
                } else {
                    let users = []

                    for (const user of fetchUsersResponseParsed.usersList) {
                        users.push({
                            id: user.id,
                            firstName: user.first_name,
                            lastName: user.last_name,
                            emailAddress: user.email,
                            isAdmin: user.is_admin
                        })
                    }

                    this.setState({ users: users })
                }
            }
        } catch (error) {
            const state = frontendHelpers.fetchWithTimeoutErrorHandler(error)
            this.setState({ redirectTologin: true, openSnackbar: true, snackbarVariant: state.snackbarVariant, snackbarMessage: state.snackbarMessage })
        }


    }

    public render() {
        const { classes, t } = this.props;
        const { users } = this.state

        /* TODO: optimize this workaround */
        let tempUsers: Array<IUser> = []
        users.map((user) => {
            if (user.id) {
                tempUsers.push(user)
            }
        })
        return (
            <div>
                <Paper className={classes.root}>
                    {users.length === 0 ? (<Loading></Loading>) :
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("USER_MANAGEMENT.TABLE_HEAD.FIRST_NAME")}</TableCell>
                                    <TableCell align="center">{t("USER_MANAGEMENT.TABLE_HEAD.LAST_NAME")}</TableCell>
                                    <TableCell align="center">{t("USER_MANAGEMENT.TABLE_HEAD.EMAIL")}</TableCell>
                                    <TableCell align="center">{t("USER_MANAGEMENT.TABLE_HEAD.ADMIN")}</TableCell>
                                    <TableCell align="center">{t("USER_MANAGEMENT.TABLE_HEAD.DELETE")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tempUsers.map((user, index) => (

                                    <TableRow key={user.id} id={String(user.id)} onClick={this.handleUserSelect(index)} hover>
                                        <TableCell align="center">{user.firstName}</TableCell>
                                        <TableCell align="center">{user.lastName}</TableCell>
                                        <TableCell align="center">{user.emailAddress}</TableCell>
                                        <TableCell align="center">{this.renderSwitchButton(index)}</TableCell>
                                        <TableCell align="center">{this.renderDeleteButton(index)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>}
                    {this.state.openUserDialog && this.renderUserDialog()}
                    {this.state.openConfirmDialog && this.renderConfirmDialog()}
                    {frontendHelpers.redirectToLogin(this.state.redirectTologin)}
                </Paper>
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.handleAddButtonClick}>
                    <AddIcon />
                </Fab>
                {this.state.openSnackbar && this.renderSnackbar()}
                {this.state.logout && this.renderLogoutAlert()}
            </div>
        );
    }
    /* render logout alert to inform the user that his session is expired */
    private renderLogoutAlert = () => {
        const { logout } = this.state
        const logoutAlertProps: ILogoutProps = {
            openLogoutAlert: logout,
            handleCloseLogoutAlert: this.handleCloseLogoutAlert
        }
        return <LogoutAlert {...logoutAlertProps}></LogoutAlert>
    }

    private handleCloseLogoutAlert = () => {
        const { cookies } = this.props
        /* clear cookies and redirect to login */
        frontendHelpers.clearCookiesAndRedirect(cookies)
    };

    /* render snackbar */
    private renderSnackbar = () => {
        const { openSnackbar, snackbarVariant, snackbarMessage } = this.state
        return (
            <NotificationsComponent
                variant={snackbarVariant}
                message={snackbarMessage}
                open={openSnackbar}
                handleClose={this.closeSnackbar}></NotificationsComponent>
        )
    }

    /* handle closing the snackbar */
    private closeSnackbar = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false })
    }


    /* open user dialog when a row is clicked */
    private handleUserSelect = (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ openUserDialog: true, userIndex: index, isNewUser: false })
    }


    /* render user dialog on the main component */
    private renderUserDialog = () => {
        const { openUserDialog, userIndex, users, isNewUser } = this.state

        const userDialogComponentProps: IUserDialogProps = {
            openUserDialog,
            user: users[userIndex],
            userIndex,
            handleCloseUserDialog: this.handleCloseUserDialog,
            updateUser: this.updateUser,
            isNewUser
        }

        return <UserDialogComponent {...userDialogComponentProps}></UserDialogComponent>
    }

    /* dismiss user dialog either when update is done or canceled */
    private handleCloseUserDialog = () => {
        this.setState({ openUserDialog: false });
    };

    /* render the switch button to indicate if the user has admin rights or not */
    private renderSwitchButton = (index: number) => {
        const { users } = this.state

        return (
            <Switch
                checked={users[index].isAdmin}
                onClick={this.handleSwitchButtonClick(index)}
                color="primary"
            />
        )
    }

    /* update the admin rights of the selected user */
    private handleSwitchButtonClick = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        const newUsers = [...this.state.users]
        const { jwtToken } = this.state
        const { t } = this.props

        /* -don't allow admin rights update when there is only one user or when there is many user but only one of them is admin
           - The goal here is to always have atleast one admin in the list
        */
        const adminNumber = newUsers.map((user) => {
            return user.isAdmin
        })

        let allowUpdate = true
        if (newUsers.length === 1) {
            allowUpdate = false
        } else {

            if (adminNumber.filter(Boolean).length === 1) {
                const adminUser = newUsers.filter(user => {
                    return user.isAdmin
                })
                if (adminUser[0].id === newUsers[index].id) {
                    allowUpdate = false
                }
            }
        }


        if (!allowUpdate) {
            this.setState({ openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.WARNING_ADMIN") })
        } else {
            newUsers[index] = {
                ...newUsers[index],
                isAdmin: !newUsers[index].isAdmin
            }

            const updateUserResponse = await fetch(`${API_BASE_URL}updateUser/${newUsers[index].id}?isAdmin=${newUsers[index].isAdmin}`, {
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            })
            if (updateUserResponse.status === 401 || updateUserResponse.status === 403) {
                this.setState({ logout: true })
            } else {
                const updateUserResponseParsed = await updateUserResponse.json()

                if (updateUserResponseParsed.error) {
                    if (updateUserResponseParsed.error.code === 404) {
                        this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.INVALID_USER") })
                    } else {
                        this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.FAILED_UPDATE") })
                    }
                } else {

                    this.setState({ users: newUsers, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.USER_UPDATED") })
                }
            }
        }

    }


    /* render user delete button */
    private renderDeleteButton = (index: number) => {

        const deleteButtonComponentProps: IDeleteButtonProps = {
            index: index,
            onDeleteButtonClick: this.onDeleteButtonClick
        }

        return <DeleteButton {...deleteButtonComponentProps}></DeleteButton>
    }

    /* open confirm dialog when deleting a user */
    private onDeleteButtonClick = (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        const { users } = this.state
        const { t, cookies } = this.props

        /* prevent deleting users if the list contains only one. */
        if (users.length === 1) {
            this.setState({ openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.WARNING_DELETE_ONLY_USER") })
        } else if (cookies.get("email") === users[index].emailAddress) {
            this.setState({ openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.WARNING_DELETE_CURRENT_USER") })

        } else {
            this.setState({ openConfirmDialog: true, userIndex: index })
        }

    }

    /* render confirm dialog on the main component */
    private renderConfirmDialog = () => {
        const { openConfirmDialog, userIndex } = this.state

        const confirmDialogComponentProps: IConfirmDialogProps = {
            openConfirmDialog,
            index: userIndex,
            withDescription: false,
            handleCloseConfirmDialog: this.handleCloseConfirmDialog,
            action: this.deleteUser,

        }

        return <ConfirmDialog {...confirmDialogComponentProps}></ConfirmDialog>

    }

    /* dismiss confirm dialog either when delete is done or canceled */
    private handleCloseConfirmDialog = () => {
        this.setState({ openConfirmDialog: false });
    };


    private handleAddButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const newUser = {
            firstName: '',
            lastName: '',
            emailAddress: '',
            isAdmin: true,
        }
        const newUsers = [...this.state.users]
        newUsers.push(newUser)
        this.setState({ openUserDialog: true, users: newUsers, userIndex: newUsers.indexOf(newUser), isNewUser: true })
    }


    private deleteUser = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {

        const newUsers = [...this.state.users]
        const { jwtToken } = this.state
        const { t } = this.props

        const deleteUser = await fetch(`${API_BASE_URL}deleteUser/${newUsers[index].id}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })
        if (deleteUser.status === 401 || deleteUser.status === 403) {
            this.setState({ logout: true })
        } else {
            const deleteUserResponse = await deleteUser.json()

            if (deleteUserResponse.error) {
                this.setState({ users: newUsers, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.FAILED_DELETE") });
            } else {
                newUsers.splice(index, 1)
                this.setState({ users: newUsers, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.USER_DELETED") });
            }
        }
    };

    private updateUser = (newUser: IUser, userIndex: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const { isNewUser, jwtToken } = this.state
        const newUsers = [...this.state.users]
        const { t } = this.props

        /* -regex function needed to be used to check the validity of the given email. Since type = "email" on  Textfield doesn't automatically check 
           - this check needs to be on the server side as well since js can be disabled on the client side.
        */
        const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (emailChecker.test(newUser.emailAddress.toLowerCase())) {
            if (!isNewUser) {

                /* update an existing user */
                if (newUser.password && newUser.password !== "") {
                    /* update user including password */
                    const updateUserResponse = await fetch(`${API_BASE_URL}updateUser/${newUser.id}?email=${newUser.emailAddress}&firstName=${newUser.firstName}&lastName=${newUser.lastName}&password=${newUser.password}`, {
                        headers: {
                            Authorization: "Bearer " + jwtToken
                        }
                    })
                    if (updateUserResponse.status === 401 || updateUserResponse.status === 403) {
                        this.setState({ logout: true })
                    } else {
                        const updateUserResponseParsed = await updateUserResponse.json()
                        if (updateUserResponseParsed.error) {
                            if (updateUserResponseParsed.error.code === 404) {
                                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.INVALID_USER") })
                            } else {
                                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.FAILED_UPDATE") })
                            }
                        } else {
                            newUsers[userIndex] = newUser
                            this.setState({ users: newUsers, openUserDialog: false, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.USER_UPDATED") })
                        }
                    }

                } else {
                    /* update user excluding password */
                    const updateUserResponse = await fetch(`${API_BASE_URL}updateUser/${newUser.id}?email=${newUser.emailAddress}&firstName=${newUser.firstName}&lastName=${newUser.lastName}&isAdmin=${newUser.isAdmin}`, {
                        headers: {
                            Authorization: "Bearer " + jwtToken
                        }
                    })
                    if (updateUserResponse.status === 401 || updateUserResponse.status === 403) {
                        this.setState({ logout: true })
                    } else {
                        const updateUserResponseParsed = await updateUserResponse.json()
                        if (updateUserResponseParsed.error) {
                            if (updateUserResponseParsed.error.code === 404) {
                                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.INVALID_USER") })
                            } else if (updateUserResponseParsed.error.code === 400) {
                                this.setState({ openUserDialog: false, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.EMAIL_MALFORMED_UPDATE") });

                            }

                            else {
                                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.FAILED_UPDATE") })
                            }
                        } else {
                            newUsers[userIndex] = newUser
                            this.setState({ users: newUsers, openUserDialog: false, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.USER_UPDATED") });
                        }
                    }
                }
            } else {
                /* add a new user */

                if (newUser.firstName !== '' && newUser.lastName !== '' && newUser.emailAddress !== '' && newUser.password !== '') {
                    const addNewUserResponse = await fetch(`${API_BASE_URL}addNewUser/${newUser.firstName}/${newUser.lastName}/${newUser.emailAddress}/${newUser.password}/${newUser.isAdmin}`, {
                        headers: {
                            Authorization: "Bearer " + jwtToken
                        }
                    })
                    if (addNewUserResponse.status === 401 || addNewUserResponse.status === 403) {
                        this.setState({ logout: true })
                    } else {
                        const addNewUserResponseParsed = await addNewUserResponse.json()
                        if (addNewUserResponseParsed.error) {
                            if (addNewUserResponseParsed.error.code === 400) {
                                this.setState({ openUserDialog: false, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.EMAIL_MALFORMED_ADD_NEW") });

                            } else {
                                this.setState({ openUserDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.FAILED_ADD_NEW") });

                            }

                        } else {
                            newUser = {
                                id: addNewUserResponseParsed.userInfo.id,
                                firstName: addNewUserResponseParsed.userInfo.firstName,
                                lastName: addNewUserResponseParsed.userInfo.lastName,
                                emailAddress: addNewUserResponseParsed.userInfo.email,
                                isAdmin: (addNewUserResponseParsed.userInfo.isAdmin === 'true'),
                            }
                            newUsers[userIndex] = newUser
                            this.setState({ users: newUsers, openUserDialog: false, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.NEW_USER_ADDED") });
                        }
                    }
                } else {
                    this.setState({ users: newUsers, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.MISSING_FIELDS") })
                }

            }
        } else {
            this.setState({ openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("USER_MANAGEMENT.NOTIFICATIONS.WARNING_MALFORMED_EMAIL") })
        }

    }

}

export default withTranslation()(withCookies(withStyles(styles)(userManagement)));