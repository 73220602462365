import React from "react";
import classNames from "classnames";
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withTranslation, WithTranslation } from "react-i18next";
import * as frontendHelpers from "../helpers/helpers"

const drawerWidth = frontendHelpers.drawerWidth;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    direction: {
      direction: "ltr"
    },
    redirect: {
      backgroundColor: "Transparent",
      backgroundRepeat: "no-repeat",
      border: "none",
      cursor: "pointer",
      overflow: "hidden",
      outline: "none"
    }
  });

export interface IMenuProps {
  menuOpen: boolean;
  viewToRender: string;
  isAdmin: string;
  handleDrawerOpen: () => (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleDrawerClose: () => (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  renderMainContent: (viewToRender: string) => JSX.Element | undefined;
  handleOnMenuItemClick: (
    menuItem: string
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  redirectContentCatalogue: () => void;
}

class MenuDrawer extends React.Component<
  IMenuProps & WithStyles<typeof styles>
  & WithTranslation>  {
  render() {
    const { classes, menuOpen, viewToRender, t } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: menuOpen
          })}
        >
          <Toolbar disableGutters={!menuOpen}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.props.handleDrawerOpen()}
              className={classNames(
                classes.menuButton,
                menuOpen && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.redirect}
              onClick={this.props.redirectContentCatalogue}
            >
              {t("MENU.TITLE")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={menuOpen}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.props.handleDrawerClose()}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {[t("MENU.CONTENT_CATALOGUE"), t("MENU.FALLBACKS_CATALOGUE"), t("MENU.PLAYLISTS_CATALOGUE")].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={this.props.handleOnMenuItemClick(text)}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          {this.renderListItems()}
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: menuOpen
          })}
        >
          <div className={classes.drawerHeader} />
          {this.props.renderMainContent(viewToRender)}
        </main>
      </div>
    );
  }

  private renderListItems = () => {
    const { isAdmin, t } = this.props;

    if (isAdmin !== "true") {
      return (
        <List>
          {[t("MENU.LOGOUT")].map((text, index) => (
            <ListItem
              button
              key={text}
              onClick={this.props.handleOnMenuItemClick(text)}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      );
    } else {
      return (
        <List>
          {[t("MENU.USER_MANAGEMENT"), t("MENU.LOGOUT")].map((text, index) => (
            <ListItem
              button
              key={text}
              onClick={this.props.handleOnMenuItemClick(text)}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      );
    }
  };
}

export default withTranslation()(withStyles(styles)(MenuDrawer));
