import Promise from "bluebird"
import fetch from "node-fetch"
import { variantIcon } from "../components/NotificationsComponent";
import * as lang from "../locales/translations/de.json";
import { Cookies } from "react-cookie";


export const weekDays = [lang.DAYS.SUNDAY, lang.DAYS.MONDAY, lang.DAYS.TUESDAY, lang.DAYS.WEDNESDAY, lang.DAYS.THURSDAY, lang.DAYS.FRIDAY, lang.DAYS.SATURDAY]
export const drawerWidth = 240;

export const fetchWithTimeoutPromise = (fetchUrl: string, fetchParams: {}, timeoutValue: number) => Promise.resolve(fetch(fetchUrl, fetchParams)).timeout(timeoutValue)



export const fetchWithTimeoutErrorHandler = (error: any): { snackbarVariant: keyof typeof variantIcon, snackbarMessage: string } => {

    if (error.name === "TimeoutError") {
        /* redirect to login and say why */
        return { snackbarVariant: "warning", snackbarMessage: lang.TIMEOUT }
    } else {
        /* server not available redirect to login */
        return { snackbarVariant: "error", snackbarMessage: lang.SERVER_NOT_AVAILABLE }
    }
}
/* deplay redirecting to login so user can read see the notification */
export const redirectToLogin = (redirectState: boolean) => {
    if (redirectState) {
        setTimeout(() => {
            window.location.href = "/"
        }, 3000)
    }
}

export const clearCookiesAndRedirect = (cookies: Cookies) => {
    cookies.remove('isAdmin')
    cookies.remove('jwtToken')
    cookies.remove('email')
    window.location.href = "/"
}