import React, { ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IUser } from './UserManagement';
import { Switch, FormControlLabel } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IUserDialogProps {
    handleCloseUserDialog: (event: React.SyntheticEvent<{}, Event>) => void;
    updateUser: (newUser: IUser, userIndex: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    openUserDialog: boolean;
    user: IUser;
    userIndex: number;
    isNewUser: boolean
}

interface IState {
    tempUser: IUser
}

class UserDialog extends React.PureComponent<IUserDialogProps & WithTranslation, IState> {

    constructor(props: IUserDialogProps & WithTranslation) {
        super(props);

        this.state = { tempUser: this.props.user }
    }

    public render() {
        const { openUserDialog, userIndex, t } = this.props
        const { tempUser } = this.state

        return (
            <div>
                <Dialog
                    maxWidth='xs'
                    open={openUserDialog}
                    onClose={this.props.handleCloseUserDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{t("USER_MANAGEMENT.LABELS.TITLE")}</DialogTitle>
                    <DialogContent>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="firstName"
                            label={t("USER_MANAGEMENT.LABELS.FIRST_NAME")}
                            type="text"
                            value={tempUser.firstName}
                            onChange={this.onTextFieldChange(t("USER_MANAGEMENT.LABELS.FIRST_NAME"))}
                            fullWidth
                        />
                        <TextField

                            margin="dense"
                            id="lastName"
                            label={t("USER_MANAGEMENT.LABELS.LAST_NAME")}
                            type="text"
                            value={tempUser.lastName}
                            onChange={this.onTextFieldChange(t("USER_MANAGEMENT.LABELS.LAST_NAME"))}
                            fullWidth
                        />
                        <TextField

                            margin="dense"
                            id="email"
                            label={t("USER_MANAGEMENT.LABELS.EMAIL")}
                            type="email"
                            value={tempUser.emailAddress}
                            onChange={this.onTextFieldChange(t("USER_MANAGEMENT.LABELS.EMAIL"))}
                            fullWidth
                        />
                        <TextField

                            margin="dense"
                            id="password"
                            label={t("USER_MANAGEMENT.LABELS.PASSWORD")}
                            type="text"
                            value={tempUser.password}
                            onChange={this.onTextFieldChange(t("USER_MANAGEMENT.LABELS.PASSWORD"))}
                            fullWidth
                        />
                        {this.renderSwitchButton()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleCloseUserDialog} color="secondary">
                            {t("CANCEL")}
                        </Button>
                        <Button onClick={this.props.updateUser(tempUser, userIndex)} color="primary">
                            {t("CONFIRM")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    private onTextFieldChange = (label: string) => async (event: ChangeEvent<HTMLTextAreaElement>) => {
        let user = { ...this.state.tempUser }
        const { t } = this.props
        switch (label) {

            case t("USER_MANAGEMENT.LABELS.FIRST_NAME"):

                user = {
                    ...user,
                    firstName: event.target.value
                }
                this.setState({ tempUser: user })

                break
            case t("USER_MANAGEMENT.LABELS.LAST_NAME"):

                user = {
                    ...user,
                    lastName: event.target.value
                }
                this.setState({ tempUser: user })

                break
            case t("USER_MANAGEMENT.LABELS.EMAIL"):

                user = {
                    ...user,
                    emailAddress: event.target.value
                }
                this.setState({ tempUser: user })

                break
            case t("USER_MANAGEMENT.LABELS.PASSWORD"):

                user = {
                    ...user,
                    password: event.target.value
                }
                this.setState({ tempUser: user })
        }

    }

    private renderSwitchButton = () => {
        const { tempUser } = this.state
        const { isNewUser } = this.props
        if (!isNewUser) {
            return
        } else {
            return (
                <FormControlLabel
                    control={
                        <Switch
                            checked={tempUser.isAdmin}
                            onClick={this.handleSwitchButtonClick}
                            color="primary"
                        />
                    }
                    label="Admin"
                />
            )
        }

    }

    private handleSwitchButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        let user = { ...this.state.tempUser }

        user = {
            ...user,
            isAdmin: !user.isAdmin
        }
        this.setState({ tempUser: user })
    }
}

export default withTranslation()(UserDialog)