import React, { FormEvent, SyntheticEvent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Redirect } from 'react-router';
import { API_BASE_URL } from '../configuration/config'
import NotificationsComponent, { variantIcon } from './NotificationsComponent';
import { Cookies, withCookies } from 'react-cookie'
import * as frontendHelpers from '../helpers/helpers';
import { WithTranslation, withTranslation } from 'react-i18next';


const styles = (theme: Theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
});

interface IState {
    email: string,
    password: string,
    redirect: string,
    openSnackbar: boolean,
    snackbarVariant: keyof typeof variantIcon,
    snackbarMessage: string,
}


interface IProps extends WithStyles<typeof styles>, WithTranslation {
    cookies: Cookies
}

class Login extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        const { cookies } = this.props;
        this.state = { email: '', password: '', redirect: '', openSnackbar: false, snackbarVariant: 'error', snackbarMessage: '' }
    }

    public render() {
        const { classes, t } = this.props;
        const { redirect } = this.state

        switch (redirect) {
            case "recovery":
                return (
                    <Redirect to={{ pathname: '/recovery' }} />
                )
            case "content":
                return (
                    <Redirect to={{ pathname: '/content' }} />
                )
            default:
                return (
                    <main className={classes.main}>
                        <CssBaseline />
                        <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {t("LOGIN.TITLE")}
                            </Typography>
                            <form className={classes.form} name="formName" onSubmit={this.handleSubmit}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="email">{t("LOGIN.EMAIL")}</InputLabel>
                                    <Input id="email" name="email" type="email" autoFocus onChange={this.handleChange("email")} />
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="password">{t("LOGIN.PASSWORD")}</InputLabel>
                                    <Input name="password" type="password" id="password" onChange={this.handleChange("password")} />
                                </FormControl>
                                <Button
                                    type="submit"
                                    id="login"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {t("LOGIN.TITLE")}
                                </Button>
                                <Button
                                    id="recovery"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                    onClick={this.onRecovery}
                                >
                                    {t("LOGIN.RECOVERY")}
                                </Button>
                            </form>
                        </Paper>
                        {this.state.openSnackbar && this.renderSnackbar()}
                    </main>
                )

        }

    }

    /* render snackbar */
    private renderSnackbar = () => {
        const { openSnackbar, snackbarVariant, snackbarMessage } = this.state
        return (
            <NotificationsComponent
                variant={snackbarVariant}
                message={snackbarMessage}
                open={openSnackbar}
                handleClose={this.closeSnackbar}></NotificationsComponent>
        )
    }

    /* handle closing the snackbar */
    private closeSnackbar = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false })
    }


    private handleChange = <K extends keyof IState>(namex: K) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(({ [namex]: event.target.value } as Pick<IState, K>));
    }

    private handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const { t } = this.props
        event.preventDefault();

        const params = new URLSearchParams()
        params.append('email', this.state.email)
        params.append('password', this.state.password)
        try {

            const authenticationResponse = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}validateLogin`, {
                method: 'POST',
                body: params
            }, 14000)

            const authenticationResponseContent = await authenticationResponse.json()

            if (authenticationResponseContent.error) {

                this.setState({ openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("LOGIN.NOTIFICATIONS.WRONG_CREDENTIALS") })

            } else {
                /* user successfully logged in */
                const isAdmin = authenticationResponseContent.isAdmin
                const jwtToken = authenticationResponseContent.jwtToken
                const email = authenticationResponseContent.email

                /* check if it's necessary to initialize spotify configuration */
                const checkResult = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}checkConfigInfo`, {
                    headers: {
                        Authorization: "Bearer " + jwtToken
                    }
                }, 14000)
                if (checkResult.status === 401 || checkResult.status === 403) {
                    this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("LOGIN.NOTIFICATIONS.AUTH_ERROR") })
                } else {
                    /* redirect to content catalogue or intialize spotify config */
                    const checkResultParsed = await checkResult.json()

                    if (checkResultParsed.error) {
                        this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("LOGIN.NOTIFICATIONS.FAILED_SPOTIFY_CONFIG") })

                    } else {
                        /* save isAdmin and jwtToken in cookies before redirecting */
                        const { cookies } = this.props

                        cookies.set('isAdmin', isAdmin)
                        cookies.set('jwtToken', jwtToken)
                        cookies.set('email', email)
                        if (checkResultParsed.url) {
                            /* initialize spotify configuration */
                            window.open(checkResultParsed.url, "_self")
                        } else {
                            /* redirect to content catalogue */
                            this.setState({ redirect: "content" })
                        }
                    }
                }
            }

        } catch (e) {
            const state = frontendHelpers.fetchWithTimeoutErrorHandler(e)
            this.setState({ openSnackbar: true, snackbarVariant: state.snackbarVariant, snackbarMessage: state.snackbarMessage })

        }
    }

    private onRecovery = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ redirect: "recovery" })
    }
}

export default withTranslation()(withCookies(withStyles(styles)(Login)));