import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { DialogContentText, DialogContent } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface ILogoutProps {
    handleCloseLogoutAlert: (event: React.SyntheticEvent<{}, Event>) => void;
    openLogoutAlert: boolean;
}

const Transition = (props: TransitionProps) => {
    return <Slide direction="up" {...props} />;
}

class LogoutAlert extends React.Component<ILogoutProps & WithTranslation> {

    render() {
        const { openLogoutAlert, t } = this.props

        return (
            <div>
                <Dialog
                    open={openLogoutAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleCloseLogoutAlert}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <DialogContentText>{t("SESSION_TERMINATED.DESCRIPTION")}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleCloseLogoutAlert} color="secondary">
                            {t("SESSION_TERMINATED.CONFIRM_BUTTON")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation()(LogoutAlert)