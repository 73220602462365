import React from 'react';
import './css/App.css';
import { BrowserRouter as Router, Route } from "react-router-dom"
import Login from './components/Login';
import PasswortRecovery from './components/PasswordRecovery'
import ContentComponent from './components/contentCatalogue/ContentComponent'
import UserManagement from './components/userManagement/UserManagement'

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Login} />
        <Route path="/recovery" component={PasswortRecovery} />
        <Route path="/content" component={ContentComponent} />
        <Route path="/userManagement" component={UserManagement} />
      </div>
    </Router>
  );
}

export default App;
