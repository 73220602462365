import React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
    button: {
        margin: theme.spacing(1),
    },
})

export interface IDeleteButtonProps {
    onDeleteButtonClick: (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    index: number;
}

class DeleteButton extends React.PureComponent<IDeleteButtonProps & WithStyles<typeof styles>> {
    public render() {
        const { classes, index } = this.props
        return (
            <IconButton color="secondary" className={classes.button} onClick={this.props.onDeleteButtonClick(index)} >
                <Delete />
            </IconButton>
        )
    }
}

export default withStyles(styles)(DeleteButton)