import React, { ChangeEvent } from 'react';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { IData } from './ContentComponent';
import { TextField, TableCell } from '@material-ui/core';
import * as frontendHelpers from '../../helpers/helpers';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface IContentTableRowProps {
    onTextFieldChange: (label: string, index: number) => (event: ChangeEvent<HTMLTextAreaElement>) => void;
    renderPlaylistSelect: (index: number, value: string) => JSX.Element;
    greetingJingleRender: (index: number, bucketPath?: string, isSotdToday?: boolean) => JSX.Element;
    songStateButton: (index: number, songState: string, isSotdToday?: boolean) => JSX.Element;
    addNewSotdButton: (index: number) => JSX.Element;
    data: IData; index: number;
}

const drawerWidth = frontendHelpers.drawerWidth;
const weekDays = frontendHelpers.weekDays


const styles = (theme: Theme) => createStyles({

    root: {
        width: '100%',
        marginTop: theme.spacing(4),
        overflowX: 'auto',
        display: 'flex'
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    dense: {
        marginTop: 19,
    },
    fab: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    grid: {
        width: '60%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    direction: {
        direction: 'ltr'
    },
    rootTabs: {
        marginTop: theme.spacing(9),
        flexGrow: 1,
    },
    tdRoot: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: "center",
        height: 69
    }
});

class ContentTableRow extends React.PureComponent<IContentTableRowProps & WithStyles<typeof styles> & WithTranslation>{


    public render() {

        const { classes, data, index, t } = this.props;
        const { publishDate, isQueued, spotifySongId, songName, artistName, playlistName, greetingText, greetingJingleBucketPath, missing, songState, spotifyPlaylistId } = data;

        const currentDate = new Date()
        const sotdDate = new Date(publishDate)
        const isSotdToday = sotdDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)

        /* display date with the following format dayName, dayNumber */
        const formatedDayNumber = ('0' + String(sotdDate.getDate())).slice(-2)
        const dateToDisplay = `${weekDays[sotdDate.getDay()]}, ${formatedDayNumber}`

        if (!missing) {
            switch (isQueued) {
                case true:
                    if ((sotdDate > currentDate) || isSotdToday) {
                        return (
                            <TableRow className={classes.row} key={publishDate} id={publishDate + index}>
                                <TableCell className={classes.tdRoot}>{dateToDisplay}</TableCell>
                                <TableCell className={classes.tdRoot}>{this.textField(t("CONTENT.LABELS.SONG_ID"), false, index, spotifySongId)}</TableCell>
                                <TableCell className={classes.tdRoot}>{songName}</TableCell>
                                <TableCell className={classes.tdRoot}>{artistName}</TableCell>
                                <TableCell className={classes.tdRoot}>{this.props.renderPlaylistSelect(index, spotifyPlaylistId)}</TableCell>
                                <TableCell className={classes.tdRoot}>{this.textField(t("CONTENT.LABELS.GREETNIG_TEXT"), false, index, greetingText)}</TableCell>
                                <TableCell className={classes.tdRoot}>{this.props.greetingJingleRender(index, greetingJingleBucketPath, true)}</TableCell>
                                <TableCell className={classes.tdRoot}>{isSotdToday ? this.props.songStateButton(index, songState, true) : this.props.songStateButton(index, songState)}</TableCell>
                            </TableRow>
                        )
                    } else {
                        return (
                            <TableRow className={classes.row} key={publishDate} id={publishDate + index}>
                                <TableCell className={classes.tdRoot}>{dateToDisplay}</TableCell>
                                <TableCell className={classes.tdRoot}>{spotifySongId}</TableCell>
                                <TableCell className={classes.tdRoot}>{songName}</TableCell>
                                <TableCell className={classes.tdRoot}>{artistName}</TableCell>
                                <TableCell className={classes.tdRoot}>{playlistName}</TableCell>
                                <TableCell className={classes.tdRoot}>{greetingText}</TableCell>
                                <TableCell className={classes.tdRoot}>{this.props.greetingJingleRender(index, greetingJingleBucketPath)}</TableCell>
                                <TableCell className={classes.tdRoot}>-</TableCell>
                            </TableRow>
                        )
                    }

                case false:
                    return (
                        <TableRow className={classes.row} key={publishDate} id={publishDate + index}>
                            <TableCell className={classes.tdRoot}>{dateToDisplay}</TableCell>
                            <TableCell className={classes.tdRoot}>{isSotdToday ? this.textField(t("CONTENT.LABELS.SONG_ID"), false, index, spotifySongId) : spotifySongId}</TableCell>
                            <TableCell className={classes.tdRoot}>{songName}</TableCell>
                            <TableCell className={classes.tdRoot}>{artistName}</TableCell>
                            <TableCell className={classes.tdRoot}>{isSotdToday ? this.props.renderPlaylistSelect(index, spotifyPlaylistId) : playlistName}</TableCell>
                            <TableCell className={classes.tdRoot}>{isSotdToday ? this.textField(t("CONTENT.LABELS.GREETNIG_TEXT"), false, index, greetingText) : greetingText}</TableCell>
                            <TableCell className={classes.tdRoot}>{isSotdToday ? this.props.greetingJingleRender(index, greetingJingleBucketPath, true) : this.props.greetingJingleRender(index, greetingJingleBucketPath)}</TableCell>
                            <TableCell className={classes.tdRoot}>{isSotdToday ? this.props.songStateButton(index, songState, true) : "-"}</TableCell>
                        </TableRow>
                    )
                default:
                    break
            }
        } else {

            if ((sotdDate > currentDate) || isSotdToday) {
                /* the sotd for this date is missing but it's either today or in the future */
                return (
                    <TableRow className={classes.row} key={publishDate} id={publishDate + index}>
                        <TableCell className={classes.tdRoot}>{dateToDisplay}</TableCell>
                        <TableCell className={classes.tdRoot}>{this.textField(t("CONTENT.LABELS.SONG_ID"), true, index, spotifySongId)}</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                        <TableCell className={classes.tdRoot}>{this.props.renderPlaylistSelect(index, spotifyPlaylistId)}</TableCell>
                        <TableCell className={classes.tdRoot}>{this.textField(t("CONTENT.LABELS.GREETNIG_TEXT"), true, index, greetingText)}</TableCell>
                        <TableCell className={classes.tdRoot}>{this.props.greetingJingleRender(index, undefined, true)}</TableCell>
                        <TableCell className={classes.tdRoot}>{this.props.addNewSotdButton(index)}</TableCell>
                    </TableRow>
                )
            } else {
                /* the sotd for this date is missing and it's in the past, it must be unmodifiable */
                return (
                    <TableRow className={classes.row} key={publishDate} id={publishDate + index}>
                        <TableCell className={classes.tdRoot}>{dateToDisplay}</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                        <TableCell className={classes.tdRoot}>{songName}</TableCell>
                        <TableCell className={classes.tdRoot}>{artistName}</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                        <TableCell className={classes.tdRoot}>-</TableCell>
                    </TableRow>
                )
            }

        }


    }

    private textField = (label: string, newItem: boolean, index: number, value?: string) => {
        const { classes } = this.props;

        return (
            <TextField
                placeholder={label}
                multiline
                rowsMax="4"
                value={value}
                fullWidth={true}
                className={(classes.textField, classes.dense)}
                margin="normal"
                onChange={this.props.onTextFieldChange(label, index)}
            />
        )

    }
}

export default withTranslation()(withStyles(styles)(ContentTableRow));
