import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { DialogContentText, DialogContent } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface IConfirmDialogProps {
    handleCloseConfirmDialog: (event: React.SyntheticEvent<{}, Event>) => void;
    action: (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    openConfirmDialog: boolean;
    index: number;
    withDescription: boolean;
    contentText?: string;
}

const Transition = (props: TransitionProps) => {
    return <Slide direction="up" {...props} />;
}

 class ConfirmDialog extends React.Component<IConfirmDialogProps & WithTranslation> {

    render() {
        const { openConfirmDialog, index, t } = this.props

        return (
            <Dialog
                open={openConfirmDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.handleCloseConfirmDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {t("CONFIRM_DIALOG.TITLE")}
                </DialogTitle>
                {this.props.withDescription ? (<DialogContent><DialogContentText>{this.props.contentText}</DialogContentText></DialogContent>) : null}
                <DialogActions>
                    <Button onClick={this.props.handleCloseConfirmDialog} color="secondary">
                        {t("CANCEL")}
                    </Button>
                    <Button onClick={this.props.action(index)} color="primary">
                        {t("CONFIRM")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(ConfirmDialog)