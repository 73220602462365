import React, { FormEvent, SyntheticEvent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Redirect } from 'react-router';
import { SettingsBackupRestore } from '@material-ui/icons';
import { API_BASE_URL } from '../configuration/config';
import NotificationsComponent, { variantIcon } from './NotificationsComponent';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
});

interface IState {
    email: string,
    redirect: boolean,
    openSnackbar: boolean,
    snackbarVariant: keyof typeof variantIcon,
    snackbarMessage: string,
}


interface IProps extends WithStyles<typeof styles>, WithTranslation {

}

class PasswordRecovery extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = { email: '', redirect: false, openSnackbar: false, snackbarVariant: 'error', snackbarMessage: '' }
    }

    public render() {
        const { classes, t } = this.props;
        const { redirect } = this.state
        if (redirect) {
            return (
                <Redirect to={{ pathname: '/' }} />
            )
        }
        else {
            return (
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <SettingsBackupRestore />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t("LOGIN.RECOVERY")}
                        </Typography>
                        <form className={classes.form} name="formName" onSubmit={this.handleSubmit}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">{t("LOGIN.EMAIL")}</InputLabel>
                                <Input id="email" name="email" type="email" autoFocus onChange={this.handleChange("email")} />
                            </FormControl>
                            <Button
                                type="submit"
                                id="confirm"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t("CONFIRM")}
                            </Button>
                        </form>
                    </Paper>
                    {this.state.openSnackbar && this.renderSnackbar()}
                </main>
            );
        }

    }

    /* render snackbar */
    private renderSnackbar = () => {
        const { openSnackbar, snackbarVariant, snackbarMessage } = this.state
        return (
            <NotificationsComponent
                variant={snackbarVariant}
                message={snackbarMessage}
                open={openSnackbar}
                handleClose={this.closeSnackbar}></NotificationsComponent>
        )
    }

    /* handle closing the snackbar */
    private closeSnackbar = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false })
    }



    private handleChange = <K extends keyof IState>(namex: K) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(({ [namex]: event.target.value } as Pick<IState, K>));
    }

    private handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const { t } = this.props
        event.preventDefault();

        const params = new URLSearchParams()
        params.append('email', this.state.email)
        try {

            const recoveryRequestResponse = await fetch(`${API_BASE_URL}userPasswordRecovery/${this.state.email}`)
            const recoveryRequestParsed = await recoveryRequestResponse.json()
            if (recoveryRequestParsed.error) {

                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("PASSWORD_RECOVERY.NOTIFICATIONS.SERVER_ERROR") })

            } else {
                this.setState({ redirect: true, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("PASSWORD_RECOVERY.NOTIFICATIONS.RECOVERY_SENT") })
            }

        } catch (e) {
            this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("PASSWORD_RECOVERY.NOTIFICATIONS.SERVER_ERROR") })
        }
    }
}

export default withTranslation()(withStyles(styles)(PasswordRecovery));