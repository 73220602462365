import React, { ReactNode } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const styles = (theme: Theme) => createStyles({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
})

export const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    warning: WarningIcon,
};

export interface INotificationsProps {
    variant: keyof typeof variantIcon;
    message: string | ReactNode;
    open: boolean;
    handleClose?: () => void;
}

class Notification extends React.PureComponent<INotificationsProps & WithStyles<typeof styles>> {
    public render() {
        const { classes, variant, message, open } = this.props
        const Icon = variantIcon[variant];
        return (
            <div>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    onClose={this.props.handleClose}
                >
                    <SnackbarContent
                        className={clsx(classes[variant], classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                                {message}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.props.handleClose}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        )
    }
}

export default withStyles(styles)(Notification)