import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import { Button, WithStyles, withStyles } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'fixed',
            bottom: 0,
            right: '60%',
            left: '40%',
            width: '100%'
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    });


interface IAddAllButtonProps {
    handleAddAllButtonClick: () => void,
    addingAll: boolean,
    doneAddingAll: boolean
}


class AddAllButton extends React.PureComponent<IAddAllButtonProps & WithStyles<typeof styles> & WithTranslation> {


    public render() {
        const { classes, addingAll, doneAddingAll, t } = this.props


        const buttonClassname = clsx({
            [classes.buttonSuccess]: doneAddingAll,
        });



        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="Save"
                        color="primary"
                        className={buttonClassname}
                        onClick={this.props.handleAddAllButtonClick}
                    >
                        {doneAddingAll ? <CheckIcon /> : <Add />}
                    </Fab>
                    {addingAll && <CircularProgress size={68} className={classes.fabProgress} />}
                </div>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={addingAll}
                        onClick={this.props.handleAddAllButtonClick}
                    >
                        {t("ADD_ALL_BUTTON")}
                    </Button>
                    {addingAll && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </div>
        );

    }

}

export default withTranslation()(withStyles(styles)(AddAllButton))