import React, { ChangeEvent } from 'react';
import { TextField, Button, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
    textField: {
        width: '90%',
        display: 'flex'
    },
    button: {
        marginLeft: theme.spacing(7),
        marginTop: theme.spacing(1)
    },
    sideBySide: {
        display: 'flex',
        flexDirection: 'row'
    }
});

export interface IAddNewElementProps {
    textFieldId: string,
    textFieldLabel: string,
    textFieldRowNumber: string,
    textFieldPlaceholder: string,
    textFieldContent: string,
    onTextChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
    onAddNewButtonClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
}

class AddNewElement extends React.PureComponent<IAddNewElementProps & WithStyles<typeof styles> & WithTranslation> {


    render() {
        const { classes, t } = this.props

        return (
            <div className={classes.sideBySide}>
                <TextField
                    id={this.props.textFieldId}
                    label={this.props.textFieldLabel}
                    multiline rows={this.props.textFieldRowNumber}
                    placeholder={this.props.textFieldPlaceholder}
                    className={this.props.classes.textField}
                    value={this.props.textFieldContent}
                    margin="normal"
                    variant="outlined"
                    onChange={this.props.onTextChange} />

                <Button variant="contained" className={classes.button} disabled={!this.props.textFieldContent} onClick={this.props.onAddNewButtonClick}>
                    {t("ADD_NEW_ELEMENT_BUTTON")}
                </Button>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(AddNewElement)) 
