import React, { ChangeEvent, SyntheticEvent } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import fetch from 'node-fetch';
import DeleteButton, { IDeleteButtonProps } from '../DeleteButton';
import ConfirmDialog, { IConfirmDialogProps } from '../ConfirmDialogComponent';
import AddNewElement, { IAddNewElementProps } from '../AddNewElement';
import { API_BASE_URL } from '../../configuration/config';
import NotificationsComponent, { variantIcon } from '../NotificationsComponent';
import { Cookies, withCookies } from 'react-cookie';
import LogoutAlert, { ILogoutProps } from '../LogoutAlert';
import Loading from '../Loading';
import * as frontendHelpers from '../../helpers/helpers';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TableSortLabel } from '@material-ui/core';

const CustomTableCell = withStyles(theme => ({
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        display: 'flex'
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});


interface IProps extends WithStyles<typeof styles>, WithTranslation {
    cookies: Cookies;
}

interface IBackupSong {
    spotify_song_id: string,
    song_name: string,
    artist_name: string,
    playlist_name: string,
    publish_date: string,
    isUsed: string,
    [key: string]: string
}

interface HeadRow {
    id: keyof IBackupSong;
    label: string;
}


type Order = 'asc' | 'desc';

interface IState {
    backupSongs: Array<IBackupSong>
    openConfirmDialog: boolean,
    backupSongIndex: number,
    backupSongsToAdd: string,
    openSnackbar: boolean,
    snackbarVariant: keyof typeof variantIcon,
    snackbarMessage: string | React.ReactNode,
    jwtToken: string,
    logout: boolean,
    redirectToLogin: boolean,
    loading: boolean,
    order: Order,
    orderBy: string
}


class CustomizedTable extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        const { cookies } = this.props
        this.state = { order: "desc", orderBy: "spotify_song_id", loading: true, backupSongs: [], openConfirmDialog: false, backupSongIndex: -1, backupSongsToAdd: '', openSnackbar: false, snackbarVariant: 'error', snackbarMessage: '', jwtToken: cookies.get('jwtToken'), logout: false, redirectToLogin: false }
    }

    public componentWillMount = async () => {
        const { jwtToken } = this.state
        const { t } = this.props

        try {
            const fetchBackUpSongsResult = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}getBackupSongsInfo`, {
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            }, 14000)
            if (fetchBackUpSongsResult.status === 401 || fetchBackUpSongsResult.status === 403) {
                this.setState({ logout: true })
            } else {
                const fetchBackUpSongsResultParsed = await fetchBackUpSongsResult.json()

                let backupSongsToDisplay: Array<IBackupSong> = []

                if (fetchBackUpSongsResultParsed.error) {

                    if (fetchBackUpSongsResultParsed.error.code === 404) {
                        this.setState({ loading: false, backupSongs: backupSongsToDisplay })
                    } else {
                        this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("SERVER_NOT_AVAILABLE") })
                    }
                } else {

                    for (const backupSong of fetchBackUpSongsResultParsed.backupSongsList) {

                        backupSongsToDisplay.push(backupSong)
                    }
                }
                this.setState({ loading: false, backupSongs: backupSongsToDisplay })
            }
        } catch (error) {
            const state = frontendHelpers.fetchWithTimeoutErrorHandler(error)
            this.setState({ redirectToLogin: true, openSnackbar: true, snackbarVariant: state.snackbarVariant, snackbarMessage: state.snackbarMessage })
        }


    }

    public render() {
        const { classes, t } = this.props;
        const { backupSongs, loading, order, orderBy } = this.state
        const headRows = this.getHeadRows()

        return (
            <div>
                {this.renderAddNewElementComponent()}
                <Paper className={classes.root}>
                    {loading ? (<Loading></Loading>) :
                        (<Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {headRows.map(row => (
                                        <CustomTableCell align="center">
                                            <TableSortLabel
                                                active={orderBy === row.id}
                                                direction={order}
                                                onClick={this.sortByProperty(row.id)}
                                            >
                                                {row.label}
                                            </TableSortLabel>
                                        </CustomTableCell>
                                    ))}
                                    <CustomTableCell align="center" >{t("FALLBACKS.TABLE_HEAD.DELETE")}</CustomTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {backupSongs.map((backupSong, index) => (
                                    <TableRow className={classes.row} key={backupSong.spotify_song_id}>
                                        <CustomTableCell align="center">{backupSong.spotify_song_id}</CustomTableCell>
                                        <CustomTableCell align="center">{backupSong.isUsed ? "Y" : "N"}</CustomTableCell>
                                        <CustomTableCell align="center">{backupSong.playlist_name}</CustomTableCell>
                                        <CustomTableCell align="center">{backupSong.song_name}</CustomTableCell>
                                        <CustomTableCell align="center">{backupSong.artist_name}</CustomTableCell>
                                        <CustomTableCell align="center">{backupSong.publish_date}</CustomTableCell>
                                        <CustomTableCell align="center">{this.renderDeleteButton(index)}</CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>)}
                    {this.state.openConfirmDialog && this.renderConfirmDialog()}
                    {this.state.logout && this.renderLogoutAlert()}
                    {frontendHelpers.redirectToLogin(this.state.redirectToLogin)}
                </Paper>
                {this.state.openSnackbar && this.renderSnackbar()}
            </div>
        );
    }
    /* append IDs to TableHead rows */
    private getHeadRows = () => {
        const { t } = this.props
        return ([
            { id: 'spotify_song_id', label: t("FALLBACKS.TABLE_HEAD.SONG_ID") },
            { id: 'isUsed', label: t("FALLBACKS.TABLE_HEAD.USED") },
            { id: 'playlist_name', label: t("FALLBACKS.TABLE_HEAD.PLAYLIST") },
            { id: 'song_name', label: t("FALLBACKS.TABLE_HEAD.SONG_NAME") },
            { id: 'artist_name', label: t("FALLBACKS.TABLE_HEAD.ARTIST_NAME") },
            { id: 'publish_date', label: t("FALLBACKS.TABLE_HEAD.USED_DATE") },
        ])
    }

    /* sort table by property */
    private sortByProperty = (property: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const { order } = this.state
        const sortedBackupSongs = [...this.state.backupSongs]

        if (order === "desc") {
            sortedBackupSongs.sort((a, b) => {

                if (a[property].toString().toLowerCase() > b[property].toString().toLowerCase()) { return -1; }
                if (a[property].toString().toLowerCase() < b[property].toString().toLowerCase()) { return 1; }
                return 0;
            })
        } else {
            sortedBackupSongs.sort((a, b) => {
                if (a[property].toString().toLowerCase() < b[property].toString().toLowerCase()) { return -1; }
                if (a[property].toString().toLowerCase() > b[property].toString().toLowerCase()) { return 1; }
                return 0;
            })
        }

        this.setState({ backupSongs: sortedBackupSongs, order: (order === "desc" ? "asc" : "desc"), orderBy: property })
    }

    /* render logout alert to inform the user that his session is expired */
    private renderLogoutAlert = () => {
        const { logout } = this.state
        const logoutAlertProps: ILogoutProps = {
            openLogoutAlert: logout,
            handleCloseLogoutAlert: this.handleCloseLogoutAlert
        }
        return <LogoutAlert {...logoutAlertProps}></LogoutAlert>
    }

    private handleCloseLogoutAlert = () => {
        const { cookies } = this.props
        /* clear cookies and redirect to login */
        frontendHelpers.clearCookiesAndRedirect(cookies)
    };

    /* render snackbar */
    private renderSnackbar = () => {
        const { openSnackbar, snackbarVariant, snackbarMessage } = this.state
        return (
            <NotificationsComponent
                variant={snackbarVariant}
                message={snackbarMessage}
                open={openSnackbar}
                handleClose={this.closeSnackbar}></NotificationsComponent>
        )
    }

    /* handle closing the snackbar */
    private closeSnackbar = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false })
    }

    /* render textfield and confirm button to add new backup songs */
    private renderAddNewElementComponent = () => {
        const { t } = this.props

        const componentProps: IAddNewElementProps = {
            textFieldId: "spotifySongId",
            textFieldLabel: t("FALLBACKS.TEXTFIELD_LABEL"),
            textFieldPlaceholder: t("FALLBACKS.TEXTFIELD_PLACEHOLDER"),
            textFieldRowNumber: "4",
            onTextChange: this.onTextChange,
            onAddNewButtonClick: this.onAddNewButtonClick,
            textFieldContent: this.state.backupSongsToAdd
        }

        return <AddNewElement {...componentProps}></AddNewElement>
    }

    /* render backup song delete button */
    private renderDeleteButton = (index: number) => {

        const deleteButtonComponentProps: IDeleteButtonProps = {
            index: index,
            onDeleteButtonClick: this.onDeleteButtonClick
        }

        return <DeleteButton {...deleteButtonComponentProps}></DeleteButton>
    }

    /* open confirm dialog when deleting a backup song */
    private onDeleteButtonClick = (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        this.setState({ openConfirmDialog: true, backupSongIndex: index })
    }

    /* render confirm dialog on the main component */
    private renderConfirmDialog = () => {
        const { openConfirmDialog, backupSongIndex } = this.state

        const confirmDialogComponentProps: IConfirmDialogProps = {
            openConfirmDialog,
            index: backupSongIndex,
            withDescription: false,
            handleCloseConfirmDialog: this.handleCloseConfirmDialog,
            action: this.deleteBackupSong,

        }

        return <ConfirmDialog {...confirmDialogComponentProps}></ConfirmDialog>

    }

    /* dismiss confirm dialog either when delete is done or canceled */
    private handleCloseConfirmDialog = () => {
        this.setState({ openConfirmDialog: false });
    };

    /* delete backup song */
    private deleteBackupSong = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const newBackUpSongs = [...this.state.backupSongs]
        const { jwtToken } = this.state
        const { t } = this.props

        const deleteBackupSongResult = await fetch(`${API_BASE_URL}deleteBackupSotd/${newBackUpSongs[index].spotify_song_id}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })
        if (deleteBackupSongResult.status === 401 || deleteBackupSongResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const deleteBackupSongResultParsed = await deleteBackupSongResult.json()

            if (deleteBackupSongResultParsed.error) {
                let errorMessage
                if (deleteBackupSongResultParsed.error.code === 400) {
                    errorMessage = t("FALLBACKS.DELETE.NOT_FOUND")
                } else {
                    errorMessage = t("FALLBACKS.DELETE.NOT_DELETED")
                }
                this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: errorMessage })
            } else {
                newBackUpSongs.splice(index, 1)
                this.setState({ backupSongs: newBackUpSongs, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("FALLBACKS.DELETE.DELETED") })
            }
        }
    }


    private onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ backupSongsToAdd: event.target.value })
    }

    private onAddNewButtonClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const { backupSongsToAdd, jwtToken } = this.state
        const { t } = this.props

        const addNewBackupSongsResult = await fetch(`${API_BASE_URL}addBackupSotd/${backupSongsToAdd}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })
        if (addNewBackupSongsResult.status === 401 || addNewBackupSongsResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const addNewBackupSongsResultParsed = await addNewBackupSongsResult.json()

            if (addNewBackupSongsResultParsed.error) {
                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("FALLBACKS.ADD.FAILED_ALL") })
            } else {
                const newBackupSongs = [...this.state.backupSongs]
                for (const addedBackupSong of addNewBackupSongsResultParsed.succeeded) {
                    newBackupSongs.push(addedBackupSong)
                }
                if (addNewBackupSongsResultParsed.code === 207) {
                    let failedBackupSongList = []
                    for (const failedBackupSong of addNewBackupSongsResultParsed.failed) {
                        let reason
                        if (failedBackupSong.code === 409) {
                            reason = t("FALLBACKS.ADD.SONG_EXISTS")
                        } else {
                            reason = t("FALLBACKS.ADD.ID_INVALID")
                        }
                        failedBackupSongList.push({ id: failedBackupSong.spotify_song_id, reason: reason })
                    }

                    /* - format the notification as follows --- failed id : reason why ---
                       - rerender the textfield component with only the failed ids
                    */
                    let failedIDs = ""
                    const failedBackupSongListFormatted = failedBackupSongList.map(({ id, reason }) => {

                        failedIDs = failedIDs + " " + id
                        return <div key={id}><b>{id}: </b>{reason}</div>
                    })



                    const warningMessage = <div>{t("FALLBACKS.ADD.WARNING_HEADER")}<br />{failedBackupSongListFormatted}</div>
                    this.setState({ backupSongsToAdd: failedIDs.trim(), backupSongs: newBackupSongs, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: warningMessage })
                }
                else {
                    let snackbarMessage
                    if (addNewBackupSongsResultParsed.succeeded.length > 1) {
                        snackbarMessage = t("FALLBACKS.ADD.ADDED_ALL")
                    } else {
                        snackbarMessage = t("FALLBACKS.ADD.ADDED_ONE")
                    }
                    this.setState({ backupSongsToAdd: "", backupSongs: newBackupSongs, openSnackbar: true, snackbarVariant: "success", snackbarMessage: snackbarMessage })
                }
            }
        }
    }

}

export default withTranslation()(withCookies(withStyles(styles)(CustomizedTable)));