import React, { ChangeEvent, SyntheticEvent } from 'react';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { RouteChildrenProps, Redirect } from 'react-router';
import { Tabs, Tab, Select, MenuItem, IconButton, AppBar, Typography } from '@material-ui/core';
import { PlayArrowOutlined, Delete, CloudUpload, Edit, AddCircle, PlayArrowRounded, DeleteForeverRounded } from '@material-ui/icons';
import moment from 'moment'
import classNames from 'classnames'
import ContentTableRow, { IContentTableRowProps } from './ContentTableRow';
import MenuDrawer, { IMenuProps } from '../MenuComponent';
import FallbacksComponent from '../fallbacksCatalogue/FallbacksComponent';
import PlaylistsComponent from '../playlistsCatalogue/PlaylistsComponent';
import UserManagement from '../userManagement/UserManagement';
import { API_BASE_URL } from '../../configuration/config';
import ConfirmDialog, { IConfirmDialogProps } from '../ConfirmDialogComponent';
import NotificationsComponent, { variantIcon } from '../NotificationsComponent';
import { Cookies, withCookies } from 'react-cookie';
import LogoutAlert, { ILogoutProps } from '../LogoutAlert';
import Loading from '../Loading';
import * as frontendHelpers from '../../helpers/helpers';
import AddAllButton from './AddAllButton';
import { WithTranslation, withTranslation } from 'react-i18next';
const fetch = require('node-fetch')

const TabContainer = ({ children, dir }: any) => {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

const drawerWidth = frontendHelpers.drawerWidth;

const styles = (theme: Theme) => createStyles({
    thHead: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: "center",
        position: "sticky",
        top: 0,
        zIndex: 200,
    },
    thBody: {
        fontSize: 14,
    },

    root: {
        width: '100%',
        marginTop: theme.spacing(7),
        overflowX: 'auto',
        display: 'flex'
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        // margin: theme.spacing(1),
    },
    input: {
        display: 'none'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    dense: {
        marginTop: 19,
    },
    fab: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    grid: {
        width: '60%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    direction: {
        direction: 'ltr'
    },
    rootTabs: {
        marginTop: theme.spacing(9),
        flexGrow: 1,
    }
});


interface IProps extends RouteChildrenProps, WithStyles<typeof styles, true>, WithTranslation {
    cookies: Cookies;

}

/* playlists information */
interface IPlaylists {
    playlistId: number;
    spotifyId: string;
    playlistName: string;
    isDefault: boolean;
}

/* data to populate the table with */
export interface IData {
    publishDate: string;
    isQueued: boolean;
    spotifySongId: string;
    songName: string;
    artistName: string;
    playlistName: string;
    spotifyPlaylistId: string;
    greetingText?: string;
    greetingJingleBucketPath: string;
    songState: string,
    greetingJingleFile?: File;
    missing: boolean;

}

interface IState {
    data: Array<IData>,
    playlists: Array<IPlaylists>,
    menuOpen: boolean,
    monthTabsValue: number,
    yearTabsValue: number,
    currentYear: number,
    viewToRender: string,
    refresh: boolean,
    openConfirmDialog: boolean,
    sotdIndex: number,
    sotdDeleted: boolean,
    defaultPlaylist: string,
    defaultPlaylistId: string,
    withDescription: boolean,
    confirmDialogDescription: string,
    openSnackbar: boolean,
    snackbarVariant: keyof typeof variantIcon,
    snackbarMessage: string,
    isAdmin: string,
    jwtToken: string,
    logout: boolean,
    redirectToLogin: boolean,
    addingAll: boolean,
    doneAddingAll: boolean,
    displayAddAllButton: boolean,
    newItemsIndexes: Array<number>,
    isSingleButton: boolean,
    todaysSotdIsChanging: boolean,
    uploading: boolean,
    updating: boolean,
    oldSpotifySongId: string,
    oldSpotifyPlaylistId: string,
    initialChangeFailed: boolean
}

class CustomizedTable extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        const { cookies } = props
        this.state = { data: [], playlists: [], menuOpen: false, monthTabsValue: moment().month(), yearTabsValue: moment().year(), currentYear: moment().year(), viewToRender: "Content", refresh: false, openConfirmDialog: false, sotdIndex: -1, sotdDeleted: false, defaultPlaylist: '', defaultPlaylistId: '', confirmDialogDescription: '', withDescription: false, openSnackbar: false, snackbarVariant: 'error', snackbarMessage: '', jwtToken: cookies.get('jwtToken'), isAdmin: cookies.get('isAdmin'), logout: false, redirectToLogin: false, addingAll: false, doneAddingAll: false, displayAddAllButton: false, newItemsIndexes: [], isSingleButton: false, todaysSotdIsChanging: false, uploading: false, updating: false, oldSpotifyPlaylistId: '', oldSpotifySongId: '', initialChangeFailed: false }

    }

    public componentWillUpdate = (nextProps: IProps, nextState: IState) => {
        if ((nextState.viewToRender !== this.state.viewToRender && nextState.viewToRender === "Content") || (nextState.refresh && !this.state.refresh)) {
            this.setState({ data: [], refresh: false });
            this.loadDataFromServer();

        }
    }
    public componentWillMount = async () => {
        this.loadDataFromServer();
    }
    private loadDataFromServer = async () => {
        const { jwtToken } = this.state
        const { t } = this.props

        try {
            /* always return 12 elements, one for every month */
            const fetchAllSongsResponse = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}getAllSongs`, {
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            }, 20000)
            if (fetchAllSongsResponse.status === 403 || fetchAllSongsResponse.status === 401) {
                this.setState({ logout: true })
            } else {
                const songsInfo = await fetchAllSongsResponse.json()

                const fetchAllPlaylistsResponse = await frontendHelpers.fetchWithTimeoutPromise(`${API_BASE_URL}getPlaylists`, {
                    headers: {
                        Authorization: "Bearer " + jwtToken
                    }
                }, 14000)
                if (fetchAllPlaylistsResponse.status === 403 || fetchAllPlaylistsResponse.status === 401) {
                    this.setState({ logout: true })
                } else {
                    const playlistsInfo = await fetchAllPlaylistsResponse.json()

                    if (songsInfo.code === 207 && playlistsInfo.code === 200) {
                        let dataToDisplay = []
                        let playlists: any = []

                        /* set the playlists to display */
                        for (const playlist of playlistsInfo.playlistsInfo) {
                            playlists.push({
                                playlistId: playlist.id,
                                spotifyId: playlist.spotify_id,
                                playlistName: playlist.playlist_name,
                                isDefault: playlist.is_default
                            })
                        }

                        /* find the default playlist */
                        const getDefaultPlaylist = () => {
                            for (const playlist of playlists) {
                                if (playlist.isDefault) {
                                    return { name: playlist.playlistName, id: playlist.playlistId, spotifyId: playlist.spotifyId }
                                }
                            }
                        }

                        const defaultPlaylist = getDefaultPlaylist()

                        for (const yearInfo of songsInfo.yearsList) {

                            for (const monthInfo of yearInfo.monthsList) {

                                /* populate the data holder with dummy values if there are no data available for a certain month  or year */
                                if (monthInfo.missingMonth || monthInfo.missingYear) {

                                    for (let i = 1; i <= monthInfo.numberOfDays; i++) {

                                        /* build the missing date with the following format YYYY-MM-DD */
                                        const formatedDay = ('0' + String(i)).slice(-2)
                                        const formatedMonth = ('0' + String(monthInfo.monthNumber)).slice(-2)
                                        const missingDate = yearInfo.year + '-' + formatedMonth + '-' + formatedDay
                                        dataToDisplay.push({
                                            publishDate: missingDate,
                                            isQueued: "-",
                                            spotifySongId: "",
                                            songName: "-",
                                            artistName: "-",
                                            playlistName: defaultPlaylist!.name,
                                            spotifyPlaylistId: defaultPlaylist!.spotifyId,
                                            greetingText: "",
                                            greetingJingleBucketPath: "",
                                            songState: "",
                                            missing: true
                                        })
                                    }
                                } else {
                                    for (const sotd of monthInfo.songsList) {

                                        /* populate the data holder with dummy values if there are no data available for a certain day  */
                                        if (sotd.missingDate) {

                                            dataToDisplay.push({
                                                publishDate: sotd.missingDate,
                                                isQueued: "-",
                                                spotifySongId: "",
                                                songName: "-",
                                                artistName: "-",
                                                playlistName: defaultPlaylist!.name,
                                                spotifyPlaylistId: defaultPlaylist!.spotifyId,
                                                greetingText: "",
                                                greetingJingleBucketPath: "",
                                                songState: "",
                                                missing: true
                                            })
                                        } else {

                                            dataToDisplay.push({
                                                publishDate: sotd.publish_date,
                                                isQueued: sotd.is_queued,
                                                spotifySongId: sotd.spotify_song_id,
                                                songName: sotd.song_name,
                                                artistName: sotd.artist_name,
                                                playlistName: sotd.playlist_name,
                                                spotifyPlaylistId: sotd.spotify_playlist_id,
                                                greetingText: sotd.greeting_text,
                                                greetingJingleBucketPath: sotd.greeting_cs_path,
                                                songState: "saved",
                                                missing: false
                                            })
                                        }
                                    }
                                }
                            }
                        }

                        /* sort the data by date */
                        dataToDisplay.sort((a, b) => {
                            if (a.publishDate < b.publishDate) { return -1; }
                            if (a.publishDate > b.publishDate) { return 1; }
                            return 0;
                        });

                        this.setState({ data: dataToDisplay, playlists: playlists, defaultPlaylist: defaultPlaylist!.name, defaultPlaylistId: defaultPlaylist!.spotifyId, addingAll: false, doneAddingAll: false, displayAddAllButton: false })
                    } else {
                        this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("SERVER_NOT_AVAILABLE") })
                    }
                }
            }
        } catch (error) {
            const state = frontendHelpers.fetchWithTimeoutErrorHandler(error)
            this.setState({ redirectToLogin: true, openSnackbar: true, snackbarVariant: state.snackbarVariant, snackbarMessage: state.snackbarMessage })
        }
    }

    public render() {
        const { logout } = this.state
        return (
            <div>
                {logout ? this.renderLogoutAlert() : this.renderMenu()}
                {frontendHelpers.redirectToLogin(this.state.redirectToLogin)}
            </div>
        )
    }

    /* render logout alert to inform the user that his session is expired */
    private renderLogoutAlert = () => {
        const { logout } = this.state
        const logoutAlertProps: ILogoutProps = {
            openLogoutAlert: logout,
            handleCloseLogoutAlert: this.handleCloseLogoutAlert
        }
        return <LogoutAlert {...logoutAlertProps}></LogoutAlert>
    }

    private handleCloseLogoutAlert = () => {
        const { cookies } = this.props
        /* clear cookies and redirect to login */
        frontendHelpers.clearCookiesAndRedirect(cookies)
    };

    private renderMenu = () => {
        const { menuOpen, viewToRender, isAdmin } = this.state

        const menuProps: IMenuProps = {
            menuOpen,
            viewToRender,
            isAdmin,
            renderMainContent: this.renderMainContent,
            handleDrawerOpen: this.handleDrawerOpen,
            handleDrawerClose: this.handleDrawerClose,
            handleOnMenuItemClick: this.handleOnMenuItemClick,
            redirectContentCatalogue: this.redirectContentCatalogue
        };
        return <MenuDrawer {...menuProps} />
    }

    private redirectContentCatalogue = () => {
        if (this.state.viewToRender !== "Content") {
            this.setState({ viewToRender: "Content" })
        } else {
            this.setState({ refresh: true })
        }

    }

    private handleOnMenuItemClick = (menuItem: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {

        this.setState({ viewToRender: menuItem })
    }

    private renderMainContent = (viewToRender: string) => {
        const { menuOpen, currentYear } = this.state
        const { classes, cookies, t } = this.props
        switch (viewToRender) {
            case t("MENU.CONTENT_CATALOGUE"):
                return (
                    <div>
                        <AppBar
                            className={classNames(classes.appBar, {
                                [classes.appBarShift]: menuOpen,
                            })} color="default" style={{ marginTop: 60 }}>
                            <Tabs
                                value={this.state.yearTabsValue}
                                onChange={this.handleYearTabsChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab value={currentYear - 1} label={String(currentYear - 1)} />
                                <Tab value={currentYear} label={String(currentYear)} />
                                <Tab value={currentYear + 1} label={String(currentYear + 1)} />

                            </Tabs>
                        </AppBar>

                        <AppBar
                            className={classNames(classes.appBar, {
                                [classes.appBarShift]: menuOpen,
                            })} color="default" style={{ marginTop: 110 }}>
                            <Tabs
                                value={this.state.monthTabsValue}
                                onChange={this.handleTabsChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                            >
                                <Tab label={t("CONTENT.MONTHS.JANUARY")} />
                                <Tab label={t("CONTENT.MONTHS.FEBRUARY")} />
                                <Tab label={t("CONTENT.MONTHS.MARCH")} />
                                <Tab label={t("CONTENT.MONTHS.APRIL")} />
                                <Tab label={t("CONTENT.MONTHS.MAY")} />
                                <Tab label={t("CONTENT.MONTHS.JUNE")} />
                                <Tab label={t("CONTENT.MONTHS.JULY")} />
                                <Tab label={t("CONTENT.MONTHS.AUGUST")} />
                                <Tab label={t("CONTENT.MONTHS.SEPTEMBER")} />
                                <Tab label={t("CONTENT.MONTHS.OCTOBER")} />
                                <Tab label={t("CONTENT.MONTHS.NOVEMBER")} />
                                <Tab label={t("CONTENT.MONTHS.DECEMBER")} />
                            </Tabs>
                        </AppBar>
                        <TabContainer>{this.renderTable()}</TabContainer>
                        {this.state.openConfirmDialog && this.renderConfirmDialog()}
                        {this.state.openSnackbar && this.renderSnackbar()}
                        {this.state.displayAddAllButton && this.renderAddAllButton()}
                    </div>
                )
            case t("MENU.FALLBACKS_CATALOGUE"):
                return (
                    <FallbacksComponent></FallbacksComponent>
                )

            case t("MENU.PLAYLISTS_CATALOGUE"):
                return (
                    <PlaylistsComponent></PlaylistsComponent>
                )
            case t("MENU.USER_MANAGEMENT"):
                return (
                    <UserManagement></UserManagement>
                )
            case t("MENU.LOGOUT"):
                cookies.remove("isAdmin");
                cookies.remove("jwtToken");
                return (
                    <Redirect to={{ pathname: '/' }} />
                )

        }

    }

    /* render add all button */
    private renderAddAllButton = () => {
        const { addingAll, doneAddingAll } = this.state

        const addAllButtonProps = {
            addingAll,
            doneAddingAll,
            handleAddAllButtonClick: this.handleAddAllButtonClick
        }

        return <AddAllButton {...addAllButtonProps}></AddAllButton>
    }

    private handleAddAllButtonClick = async () => {
        const { addingAll } = this.state

        if (!addingAll) {
            this.setState({ addingAll: true, doneAddingAll: false, isSingleButton: false })
            await this.uploadAllElements()
        }
    }

    /* upload all elements handler */
    private uploadAllElements = async () => {
        const UpdatedNewItemsIndexes = [...this.state.newItemsIndexes];
        const { t } = this.props

        /* if there is no more items to upload remove add all button from view */
        if (UpdatedNewItemsIndexes.length === 0) {
            this.setState({ addingAll: false, doneAddingAll: true, newItemsIndexes: [] })

            /* rerender after 2 seconds to remove add all button */
            setTimeout(() => {
                this.setState({ doneAddingAll: false, displayAddAllButton: false })
            }, 2000);
        } else {
            const checkResult = await this.checkSotdValidity(UpdatedNewItemsIndexes[0]);
            if (checkResult!.code === 200) {
                /* perform the actual upload if all checks are done successfully */
                await this.uploadSotdHandler(UpdatedNewItemsIndexes[0])

            } else if (checkResult!.code === 409) {
                if (checkResult!.existsInSamePlaylist) {
                    /* the requested song has been already set for the requested playlist, ask the user to confirm his choice nevertheless */
                    const confirmDialogDescription = t("CONTENT.UPLOAD_SOTD.SOTD_IN_PLAYLIST_EXISTS", { spotifySongId: checkResult!.song_id, spotifyPlaylistId: checkResult!.playlist_name })
                    this.setState({ sotdIndex: UpdatedNewItemsIndexes[0], openConfirmDialog: true, withDescription: true, confirmDialogDescription: confirmDialogDescription })
                } else {
                    this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPLOAD_SOTD.SOTD_FOR_DATE_EXISTS") })

                    this.updateItemsToUploadList()
                }
            } else {
                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPLOAD_SOTD.SERVER_ERROR") })

                this.updateItemsToUploadList()
            }
        }
    }

    /* delete first item from the list and rerender */
    private updateItemsToUploadList = () => {
        const UpdatedNewItemsIndexes = [...this.state.newItemsIndexes];

        UpdatedNewItemsIndexes.splice(0, 1)
        this.setState({ newItemsIndexes: UpdatedNewItemsIndexes }, () => {
            setTimeout(async () => {
                await this.uploadAllElements();
            }, 700);
        });
    }


    /* render table for a specific container */
    private renderTable = () => {
        const { classes, t } = this.props
        const { data } = this.state;

        const rows = this.state.data.map(this.renderRow);
        const headStyle = { head: classes.thHead, body: classes.thBody };

        return (
            <Paper className={classes.root} style={{
                position: "absolute",
                top: 176,
                left: 24,
                right: 24,
                bottom: 0,
                marginTop: 0,
                height: "calc(100% - 176px)",
                width: "calc(100% - 48px)",
            }}>
                {data.length === 0 ? (<Loading></Loading>) :

                    (<Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={headStyle} >{t("CONTENT.TABLE_HEAD.DATE")}</TableCell>
                                <TableCell classes={headStyle}>{t("CONTENT.TABLE_HEAD.SONG_ID")}</TableCell>
                                <TableCell classes={headStyle}>{t("CONTENT.TABLE_HEAD.SONG_NAME")}</TableCell>
                                <TableCell classes={headStyle}>{t("CONTENT.TABLE_HEAD.ARTIST_NAME")}</TableCell>
                                <TableCell classes={headStyle}>{t("CONTENT.TABLE_HEAD.PLAYLIST")}</TableCell>
                                <TableCell classes={headStyle} >{t("CONTENT.TABLE_HEAD.GREETING_TEXT")}</TableCell>
                                <TableCell classes={headStyle} style={{ width: 188 }}>{t("CONTENT.TABLE_HEAD.GREETING_JINGLE")}</TableCell>
                                <TableCell classes={headStyle} style={{ width: 48 }}>{t("CONTENT.TABLE_HEAD.STATUS")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>)}
            </Paper>
        )

    }

    private handleDrawerOpen = () => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ menuOpen: true });
    };

    private handleDrawerClose = () => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ menuOpen: false });
    };

    handleTabsChange = (event: ChangeEvent<{}>, value: number) => {
        this.setState({ monthTabsValue: value });
    };

    handleYearTabsChange = (event: ChangeEvent<{}>, value: number) => {
        this.setState({ yearTabsValue: value });
    };

    /* render every row of the requested table of the requested month */
    private renderRow = (data: IData, index: number) => {
        const { monthTabsValue, yearTabsValue } = this.state;
        const { publishDate } = data;

        if (parseInt(publishDate.substr(0, 4)) !== yearTabsValue || parseInt(publishDate.substr(5, 2)) - 1 !== monthTabsValue) {
            return null;
        }

        const componentProps: IContentTableRowProps = {
            data, index, renderPlaylistSelect: this.renderPlaylistSelect,
            greetingJingleRender: this.greetingJingleRender,
            songStateButton: this.songStateButton,
            onTextFieldChange: this.onTextFieldChange,
            addNewSotdButton: this.addNewSotdButton,
        };
        return <ContentTableRow key={data.publishDate} {...componentProps} />
    }

    private renderPlaylistSelect = (index: number, value: string) => {
        const { playlists } = this.state
        //playlists[0].spotifyId
        return (<Select
            value={value}
            fullWidth={true}
            onChange={this.onPlaylistChange(index)}
        >
            {playlists.map((playlist: IPlaylists) => <MenuItem value={playlist.spotifyId} key={playlist.playlistName}>
                <em>{playlist.playlistName}</em>
            </MenuItem>)}
        </Select>);

    }


    private greetingJingleRender = (index: number, bucketPath?: string, isSotdToday?: boolean) => {

        const { classes } = this.props
        const { data } = this.state
        const currentDate = new Date()
        const sotdDate = new Date(data[index].publishDate)

        if (bucketPath) {
            /* if sotd is today or in the future make the jingle editable regardless if it's queued or not */
            if ((sotdDate > currentDate) || isSotdToday) {

                return (
                    <div>
                        <input
                            accept=".mp3,.wav,.aac,.m4a"
                            className={classes.input}
                            id={String(index)}
                            type="file"
                            onChange={this.onUploadJingleFileChange(index)}
                        />
                        <label htmlFor={String(index)}>
                            <IconButton component="span" className={classes.button} color="primary">
                                <CloudUpload />
                            </IconButton>
                        </label>

                        <IconButton style={{ color: "green" }} className={classes.button} onClick={this.playJingle(index)}>
                            <PlayArrowRounded />
                        </IconButton>

                        <IconButton color="secondary" className={classes.button} onClick={this.deleteJingle(index)}>
                            <DeleteForeverRounded />
                        </IconButton>
                    </div>
                )
            } else {
                return (
                    <div>

                        <IconButton color="primary" className={classes.button} onClick={this.playJingle(index)}>
                            <PlayArrowOutlined />
                        </IconButton>

                    </div>
                )
            }

        } else {
            if ((sotdDate > currentDate) || isSotdToday) {
                return (
                    <div>
                        <input
                            accept=".mp3,.wav,.aac,.m4a"
                            className={classes.input}
                            id={String(index)}
                            type="file"
                            onChange={this.onUploadJingleFileChange(index)}
                        />
                        <label htmlFor={String(index)}>
                            <IconButton component="span" className={classes.button} color="primary">
                                <CloudUpload />
                            </IconButton>
                        </label>
                    </div>
                )
            } else {
                return (<div>-</div>)
            }
        }
    }

    private songStateButton = (index: number, songState: string, isSotdToday?: boolean) => {

        const { classes } = this.props;

        if (songState === "saved" && !isSotdToday) {

            return (
                <IconButton className={classes.button} color="secondary" onClick={this.onDeleteButtonClick(index)}>
                    <Delete />
                </IconButton>
            )
        } else {
            return (

                <IconButton className={classes.button} color="primary" disabled={songState === "saved"} onClick={this.updateSotd(index)}>
                    <Edit />
                </IconButton>
            )
        }
    }

    /* open confirm dialog when deleting a user */
    private onDeleteButtonClick = (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        this.setState({ openConfirmDialog: true, sotdIndex: index, withDescription: false })
    }

    /* render confirm dialog on the main component */
    private renderConfirmDialog = () => {
        const { openConfirmDialog, sotdIndex, withDescription, confirmDialogDescription, todaysSotdIsChanging } = this.state
        const confirmDialogComponentProps: IConfirmDialogProps = {
            openConfirmDialog,
            index: sotdIndex,
            withDescription: withDescription,
            handleCloseConfirmDialog: this.handleCloseConfirmDialog,
            action: withDescription ? (todaysSotdIsChanging ? this.uploadTodaysSotd : this.uploadSotd) : this.deleteSotd,
            contentText: confirmDialogDescription

        }

        return <ConfirmDialog {...confirmDialogComponentProps}></ConfirmDialog>
    }


    /* render snackbar */
    private renderSnackbar = () => {
        const { openSnackbar, snackbarVariant, snackbarMessage } = this.state
        return (
            <NotificationsComponent
                variant={snackbarVariant}
                message={snackbarMessage}
                open={openSnackbar}
                handleClose={this.closeSnackbar}></NotificationsComponent>
        )
    }

    /* handle closing the snackbar */
    private closeSnackbar = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false })
    }

    /* dismiss confirm dialog either when delete is done or canceled */
    private handleCloseConfirmDialog = () => {

        if (!this.state.isSingleButton) {
            if (this.state.newItemsIndexes.length <= 1) {
                this.setState({ openConfirmDialog: false, addingAll: false, doneAddingAll: false, todaysSotdIsChanging: false });
            } else {
                this.updateItemsToUploadList()
            }
        } else {

            this.setState({ openConfirmDialog: false, todaysSotdIsChanging: false });
        }
    };

    private deleteSotd = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const newData = [...this.state.data]
        const { defaultPlaylist, defaultPlaylistId, jwtToken } = this.state
        const { t } = this.props

        const deleteSotdResponse = await fetch(`${API_BASE_URL}deleteSotd/${newData[index].publishDate}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })

        if (deleteSotdResponse.status === 401 || deleteSotdResponse.status === 403) {
            this.setState({ logout: true })
        } else {
            const deleteSotdResponseParsed = await deleteSotdResponse.json()

            if (deleteSotdResponseParsed.error) {
                if (deleteSotdResponseParsed.error.code === 404) {
                    this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.DELETE.NOT_FOUND") })
                } else {
                    this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.DELETE.NOT_DELETED") })
                }

            } else {

                newData[index] = {
                    publishDate: newData[index].publishDate,
                    isQueued: true,
                    spotifySongId: "",
                    songName: "-",
                    artistName: "-",
                    playlistName: defaultPlaylist,
                    spotifyPlaylistId: defaultPlaylistId,
                    greetingText: "",
                    greetingJingleBucketPath: "",
                    songState: "",
                    missing: true
                }

                this.setState({ data: newData, openConfirmDialog: false, sotdDeleted: true, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("CONTENT.DELETE.DELETED") })
            }
        }

    }


    private onPlaylistChange = (index: number) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {

        const newData = [...this.state.data];

        const newSpotifyPlaylistId = (event.target.value as string)
        /* find the default playlist */
        const getPlaylistName = () => {
            for (const playlist of this.state.playlists) {
                if (playlist.spotifyId === newSpotifyPlaylistId) {
                    return playlist.playlistName
                }
            }
        }
        const newPlaylistName = getPlaylistName()

        newData[index] = {
            ...newData[index],
            playlistName: newPlaylistName as string,
            spotifyPlaylistId: newSpotifyPlaylistId,
            songState: "save"
        };

        this.setState({ data: newData });

    }

    private onTextFieldChange = (label: string, index: number) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newData = [...this.state.data];
        const { t } = this.props

        switch (label) {
            case t("CONTENT.LABELS.SONG_ID"):
                const tempNewItemsIndexes = [...this.state.newItemsIndexes]
                if (!tempNewItemsIndexes.includes(index)) {
                    tempNewItemsIndexes.push(index)
                }
                newData[index!] = {
                    ...newData[index!],
                    spotifySongId: event.target.value,
                    songState: "save"
                };
                (newData[index].missing ? this.setState({ data: newData, displayAddAllButton: true, newItemsIndexes: tempNewItemsIndexes }) : this.setState({ data: newData }));
                break
            case t("CONTENT.LABELS.GREETNIG_TEXT"):
                newData[index!] = {
                    ...newData[index!],
                    greetingText: event.target.value,
                    songState: "save"
                };
                this.setState({ data: newData });
                break
            default:
                break
        }

    }

    private onUploadJingleFileChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const { t } = this.props

        if (event.target.files![0].type !== 'audio/mp3' && event.target.files![0].type !== 'audio/wav' && event.target.files![0].type !== 'audio/aac' && event.target.files![0].type !== 'audio/mp4' && event.target.files![0].type !== 'audio/m4a' && event.target.files![0].type !== 'audio/x-m4a') {
            this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.WARNING_JINGLE") })
        } else {
            const newData = [...this.state.data];

            newData[index] = {
                ...newData[index],
                greetingJingleFile: event.target.files![0],
                songState: "save"
            };

            this.setState({ data: newData });
        }

    }

    private playJingle = (index: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {

        const jingleURL = this.state.data[index].greetingJingleBucketPath
        return (
            window.open(jingleURL, "_blank")
        )
    }

    private deleteJingle = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {

        const newData = [...this.state.data]
        const { jwtToken } = this.state
        const { t } = this.props

        const deleteJingleResult = await fetch(`${API_BASE_URL}deleteJingle/${newData[index].publishDate}?JingleBucketPath=${newData[index].greetingJingleBucketPath}`, {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })

        if (deleteJingleResult.status === 401 || deleteJingleResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const deleteJingleResultParsed = await deleteJingleResult.json()

            if (deleteJingleResultParsed.code !== 200) {
                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.DELETE_JINGLE.ERROR") })
            } else {
                newData[index] = {
                    ...newData[index],
                    greetingJingleBucketPath: ""
                }

                this.setState({ data: newData, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("CONTENT.DELETE_JINGLE.SUCCESS") })
            }
        }

    }

    private updateSotd = (index: number) => async () => {
        this.updateSotdMainHandler(index)
    }

    private updateSotdMainHandler = async (index: number) => {
        const { todaysSotdIsChanging } = this.state
        const { t } = this.props
        const newData = [...this.state.data];
        const sotdToUpdate = newData[index];

        /* check if the updated sotd is of the current day */
        const currentDate = new Date()
        const sotdDate = new Date(sotdToUpdate.publishDate)
        const isSotdToday = sotdDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)
        if (!todaysSotdIsChanging) {

            if (isSotdToday) {
                /* inform user that the sotd  is going to be directly uploaded to the spotify playlist */
                const confirmDialogDescription = t("CONTENT.UPLOAD_SOTD.SOTD_IS_TODAY_HINT")
                this.setState({ sotdIndex: index, openConfirmDialog: true, withDescription: true, confirmDialogDescription: confirmDialogDescription, todaysSotdIsChanging: true, updating: true, uploading: false })
            } else {
                /* update normal sotd */
                await this.updateSotdHandler(index, newData, sotdToUpdate)
            }
        } else {
            /* update database and spotify playlist */
            await this.updateSotdHandler(index, newData, sotdToUpdate)

            if (isSotdToday) {
                if (!this.state.initialChangeFailed) {
                    await this.uploadTodaysSotdToSpotify(index)
                }
            }

            this.setState({ todaysSotdIsChanging: false, initialChangeFailed: false })
        }
    }

    private updateSotdHandler = async (index: number, newData: IData[], sotdToUpdate: IData) => {
        const { jwtToken } = this.state
        const { t } = this.props
        let oldSpotifyPlaylistId = ''
        let oldSpotifySongId = ''
        /* delete sotd prefix before updating */
        const spotifySongId = sotdToUpdate.spotifySongId.split(/[:]+/)
        const spotifySongIdFormatted = spotifySongId[spotifySongId.length - 1]

        if (sotdToUpdate.greetingJingleFile) {
            /* update sotd including jingle */

            const params = new FormData()
            params.append('file', sotdToUpdate.greetingJingleFile!)
            const fetchResult = await fetch(`${API_BASE_URL}updateSotd/${sotdToUpdate.publishDate}?spotifySongID=${spotifySongIdFormatted}&spotifyPlaylistID=${sotdToUpdate.spotifyPlaylistId}&greetingText=${sotdToUpdate.greetingText}&greetingSoundFile=exists`, {
                method: 'POST',
                body: params,
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            })

            if (fetchResult.status === 401 || fetchResult.status === 403) {
                this.setState({ logout: true })
            } else {
                const updateResult = await fetchResult.json()

                if (updateResult.error) {
                    if (updateResult.error.code === 400) {
                        this.setState({ initialChangeFailed: true, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPDATE_SOTD.MALFORMED_REQUEST") });
                    } else {
                        this.setState({ initialChangeFailed: true, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPDATE_SOTD.SERVER_ERROR") });
                    }
                } else {
                    if ((updateResult[0].succeeded[1].oldSpotifyPlaylistId)) {
                        oldSpotifyPlaylistId = updateResult[0].succeeded[1].oldSpotifyPlaylistId
                    }
                    if (updateResult[0].succeeded[0].oldSpotifySongId) {
                        oldSpotifySongId = updateResult[0].succeeded[0].oldSpotifySongId
                    }

                    newData[index] = {
                        ...newData[index],
                        spotifySongId: spotifySongIdFormatted,
                        songState: 'saved',
                        greetingJingleBucketPath: updateResult[0].succeeded[3].bucket_path
                    }
                    this.setState({ openConfirmDialog: false, data: newData, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("CONTENT.UPDATE_SOTD.UPDATED"), oldSpotifyPlaylistId: oldSpotifyPlaylistId, oldSpotifySongId: oldSpotifySongId });
                }
            }

        } else {
            /* update sotd excluding jingle */
            const fetchResult = await fetch(`${API_BASE_URL}updateSotd/${sotdToUpdate.publishDate}?spotifySongID=${spotifySongIdFormatted}&spotifyPlaylistID=${sotdToUpdate.spotifyPlaylistId}&greetingText=${sotdToUpdate.greetingText}`, {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + jwtToken
                }
            })

            if (fetchResult.status === 401 || fetchResult.status === 403) {
                this.setState({ logout: true })
            } else {
                const updateResult = await fetchResult.json()


                if (updateResult.error) {
                    if (updateResult.error.code === 400) {
                        this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPDATE_SOTD.MALFORMED_REQUEST") });
                    } else {
                        this.setState({ openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPDATE_SOTD.SERVER_ERROR") });
                    }
                } else {
                    if ((updateResult[0].succeeded[1].oldSpotifyPlaylistId)) {
                        oldSpotifyPlaylistId = updateResult[0].succeeded[1].oldSpotifyPlaylistId
                    }
                    if (updateResult[0].succeeded[0].oldSpotifySongId) {
                        oldSpotifySongId = updateResult[0].succeeded[0].oldSpotifySongId
                    }

                    newData[index] = {
                        ...newData[index],
                        spotifySongId: spotifySongIdFormatted,
                        songName: updateResult[0].succeeded[0].songName,
                        artistName: updateResult[0].succeeded[0].artistName,
                        songState: 'saved'
                    }
                    this.setState({ openConfirmDialog: false, data: newData, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("CONTENT.UPDATE_SOTD.UPDATED"), oldSpotifyPlaylistId: oldSpotifyPlaylistId, oldSpotifySongId: oldSpotifySongId });
                }
            }
        }
    }


    private addNewSotdButton = (index: number) => {
        const { classes } = this.props;

        return (
            <div>
                <IconButton color="primary" className={classes.button} onClick={this.onAddNewSotdButtonClick(index)}>
                    <AddCircle />
                </IconButton>
            </div>

        )
    }


    private onAddNewSotdButtonClick = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ isSingleButton: true })
        const { t } = this.props

        const checkResult = await this.checkSotdValidity(index)
        if (checkResult!.code === 200) {
            /* perform the actual upload if all checks are done successfully */
            await this.uploadSotdHandler(index)

        } else if (checkResult!.code === 409) {
            if (checkResult!.existsInSamePlaylist) {
                /* the requested song has been already set for the requested playlist, ask the user to confirm his choice nevertheless */
                const confirmDialogDescription = t("CONTENT.UPLOAD_SOTD.SOTD_IN_PLAYLIST_EXISTS", { spotifySongId: checkResult!.song_id, spotifyPlaylistId: checkResult!.playlist_name })
                this.setState({ sotdIndex: index, openConfirmDialog: true, withDescription: true, confirmDialogDescription: confirmDialogDescription })
            } else {
                this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPLOAD_SOTD.SOTD_FOR_DATE_EXISTS") })
            }
        } else {
            this.setState({ openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPLOAD_SOTD.SERVER_ERROR") })
        }
    }

    private checkSotdValidity = async (index: number) => {
        const newData = [...this.state.data]
        const { jwtToken } = this.state
        const playlistId = newData[index].spotifyPlaylistId

        /* delete spotify:track: if exists */
        const spotifySongId = newData[index].spotifySongId.split(/[:]+/)
        const spotifySongIdFormatted = spotifySongId[spotifySongId.length - 1]


        const fetchCheckResult = await fetch(`${API_BASE_URL}checkSotd?spotifySongId=${spotifySongIdFormatted}&playlistId=${playlistId}&playlistName=${newData[index].playlistName}&publishTime=${newData[index].publishDate}`, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })

        if (fetchCheckResult.status === 401 || fetchCheckResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const checkResult = await fetchCheckResult.json()

            if (checkResult.error) {
                if (checkResult.error.code === 409) {
                    if (checkResult.error.spotify_song_id) {
                        return ({ code: 409, existsInSamePlaylist: true, song_id: checkResult.error.spotify_song_id, playlist_name: checkResult.error.spotify_playlist_name })
                    } else {
                        return ({ code: 409, existsForGivenDate: true })
                    }
                } else {
                    return ({ code: 500, serverError: true })
                }
            } else {
                return ({ code: 200, canBeUploaded: true })
            }
        }
    }

    /* triggered in case the user confirms the upload of an sotd in the same playlist */
    private uploadSotd = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        await this.uploadSotdHandler(index)

    }

    /* triggered in case the user confirms the upload of todays song */
    private uploadTodaysSotd = (index: number) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        /* check if it's a fresh upload or an update */
        if (this.state.uploading) {
            await this.uploadSotdHandler(index)
        } else if (this.state.updating) {
            this.updateSotdMainHandler(index)
        }
    }

    private uploadSotdHandler = async (index: number) => {
        const newData = [...this.state.data]
        const { jwtToken, todaysSotdIsChanging } = this.state
        const { t } = this.props
        const UpdatedNewItemsIndexes = [...this.state.newItemsIndexes]

        const playlistId = newData[index].spotifyPlaylistId

        /* delete spotify:track: if exists */
        const spotifySongId = newData[index].spotifySongId.split(/[:]+/)
        const spotifySongIdFormatted = spotifySongId[spotifySongId.length - 1].split("?")[0]
        const params = new FormData()
        params.append('spotifySongId', spotifySongIdFormatted)
        params.append('playlistId', String(playlistId))
        params.append('playlistName', newData[index].playlistName)
        params.append('publishTime', newData[index].publishDate)

        if (newData[index].greetingText) {
            params.append('greetingText', newData[index].greetingText!)
        }
        if (newData[index].greetingJingleFile) {
            params.append('file', newData[index].greetingJingleFile!)
        }

        /* check if the uploaded sotd is of the current day */
        const currentDate = new Date()
        const sotdDate = new Date(newData[index].publishDate)
        const isSotdToday = sotdDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)
        if (!todaysSotdIsChanging) {

            if (isSotdToday) {
                /* inform user that the sotd  is going to be directly uploaded to the spotify playlist */
                const confirmDialogDescription = t("CONTENT.UPLOAD_SOTD.SOTD_IS_TODAY_HINT")
                this.setState({ sotdIndex: index, openConfirmDialog: true, withDescription: true, confirmDialogDescription: confirmDialogDescription, todaysSotdIsChanging: true, uploading: true, updating: false })

            } else {
                /* upload normal sotd */
                await this.uploadFetchRequest(params, jwtToken, index, newData, UpdatedNewItemsIndexes, spotifySongIdFormatted)
                if (!this.state.isSingleButton) {
                    this.updateItemsToUploadList()
                }
            }
        } else {
            /* upload and update spotify playlist */
            await this.uploadFetchRequest(params, jwtToken, index, newData, UpdatedNewItemsIndexes, spotifySongIdFormatted)
            if (!this.state.isSingleButton) {
                this.updateItemsToUploadList()
            }
            if (isSotdToday) {
                if (!this.state.initialChangeFailed) {
                    await this.uploadTodaysSotdToSpotify(index)
                }

            }

            this.setState({ todaysSotdIsChanging: false, initialChangeFailed: false })
        }
    }
    private uploadFetchRequest = async (params: FormData, jwtToken: string, index: number, newData: IData[], UpdatedNewItemsIndexes: number[], spotifySongIdFormatted: string) => {
        const { t } = this.props
        const fetchResult = await fetch(`${API_BASE_URL}validateUpload`, {
            method: 'POST',
            body: params,
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })

        if (fetchResult.status === 401 || fetchResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const uploadResult = await fetchResult.json()
            if (uploadResult.error) {

                this.setState({ initialChangeFailed: true, openConfirmDialog: false, openSnackbar: true, snackbarVariant: "error", snackbarMessage: t("CONTENT.UPLOAD_SOTD.SERVER_ERROR") })
            } else {
                let greeting_cs_path: string = ''
                let greeting_text: string = ''
                for (const succeededUpload of uploadResult.succeeded) {
                    if (succeededUpload.bucket_path) {
                        greeting_cs_path = succeededUpload.bucket_path
                    }
                    if (succeededUpload.greeting_text) {
                        greeting_text = succeededUpload.greeting_text
                    }

                }
                newData[index] = {
                    ...newData[index],
                    spotifySongId: spotifySongIdFormatted,
                    songState: "saved",
                    missing: false,
                    isQueued: true,
                    songName: uploadResult.succeeded[0].song_name,
                    artistName: uploadResult.succeeded[0].artist_name,
                    greetingJingleBucketPath: greeting_cs_path,
                    greetingText: greeting_text
                }

                /* if the user is adding songs individually, display the add all button untill there is one element left to add. */
                if (this.state.isSingleButton) {
                    UpdatedNewItemsIndexes.splice(UpdatedNewItemsIndexes.indexOf(index), 1)
                    this.setState({ newItemsIndexes: UpdatedNewItemsIndexes, addingAll: false, doneAddingAll: false, displayAddAllButton: (UpdatedNewItemsIndexes.length <= 1 ? false : true), openConfirmDialog: false, data: newData, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("CONTENT.UPLOAD_SOTD.UPLOADED") });
                } else {
                    this.setState({ openConfirmDialog: false, data: newData, openSnackbar: true, snackbarVariant: "success", snackbarMessage: t("CONTENT.UPLOAD_SOTD.UPLOADED") });
                }
            }
        }
    }

    private uploadTodaysSotdToSpotify = async (index: number) => {
        const { jwtToken, data } = this.state
        const { t } = this.props

        const pushResult = await fetch(`${API_BASE_URL}pushToPlaylist/${data[index].spotifyPlaylistId}/${data[index].spotifySongId}?oldSpotifySongId=${this.state.oldSpotifySongId}&oldSpotifyPlaylistId=${this.state.oldSpotifyPlaylistId}`, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        })

        if (pushResult.status === 401 || pushResult.status === 403) {
            this.setState({ logout: true })
        } else {
            const pushParsed = await pushResult.json()
            if (pushParsed.error) {
                /* inform user that only the database was updated and not the spotify account */
                setTimeout(() => {
                    this.setState({ updating: false, uploading: false, openSnackbar: true, snackbarVariant: "warning", snackbarMessage: t("CONTENT.UPDATE_SOTD.UPDATE_TODAYS_SOTD_WARNING") })
                }, 3000)
            } else {
                this.setState({ updating: false, uploading: false })
            }
        }
    }
}

export default withTranslation()(withCookies(withStyles(styles)(CustomizedTable)));